import React from 'react';
import styled from 'styled-components';

const ButtonComponent = styled.button`
  height: 30px;
  padding: 0 20px;
  border-radius: 5px;
  font-weight: 700;

  &:hover {
    filter: opacity(80%);
  }
`;

const Button = (props) => {
  return (
    <ButtonComponent
      style={{ backgroundColor: props.backgroundColor, color: props.color }}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.text}
    </ButtonComponent>
  );
};

export default Button;
