import { useState } from 'react';
import styled from 'styled-components';
import BlockIcon from '@mui/icons-material/Block';
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
import ActionButton from '../../../atoms/actionButtons';
import DeleteTeamModal from './disableTeam';
import EditTeamModal from './editTeamModal';

const ActionButtonWrapper = styled.div`
  display: flex;
  gap: 10px;
  justify-content: center;
`;

const Button = styled.button`
  width: 30px;
  height: 30px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    filter: opacity(50%);
  }
`;

const List = styled.ul`
  li::marker {
    margin-right: 0;
  }
`;

const EditButton = styled(Button)`
  background-color: #5bc6e8;
`;

const DeleteButton = styled(Button)`
  background-color: #ff5353;
`;

const Icon = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;

  img {
    width: 100%;
  }
`;

const ListItem = ({ team, refreshData }) => {
  const [deleteTeamModal, setDeleteTeamModal] = useState(false);
  const [editTeamModal, setEditTeamModal] = useState(false);

  return (
    <tr style={{ filter: `opacity(${team.status ? '100%' : '30%'})` }}>
      <td style={{ fontWeight: '700' }}>{team.name}</td>
      <td>
        {team.leagues.map(({ name }, index) => (
          <p
            style={{
              color:
                index % 2 === 0 ? 'var(--primaryText)' : 'var(--secondaryText)',
              fontWeight: '500',
            }}
          >
            {name}
          </p>
        ))}
      </td>
      <td>{team.sport.name}</td>
      <td style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
        <Icon>
          <img
            src={
              team.image !== ''
                ? team.image
                : 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTH4d1ldfiI0npUaDbUXnRHjumUJmSpIaKErA&usqp=CAU'
            }
            alt=""
          />
        </Icon>
      </td>
      <td>
        <ActionButtonWrapper>
          <ActionButton
            text={
              <EditIcon
                style={{ width: '25px', height: '25px', color: '#fff' }}
              />
            }
            color="#fff"
            backgroundColor="#5bc6e8"
            onClick={() => setEditTeamModal(true)}
            disabled={!team.status}
          />
          {team.status ? (
            <ActionButton
              text={
                <BlockIcon
                  style={{ width: '25px', height: '25px', color: '#fff' }}
                />
              }
              color="#fff"
              backgroundColor="#ff5353"
              onClick={() => setDeleteTeamModal(true)}
            />
          ) : (
            <ActionButton
              text={
                <CheckIcon
                  style={{ width: '25px', height: '25px', color: '#fff' }}
                />
              }
              color="#fff"
              backgroundColor="#46c791"
              onClick={() => setDeleteTeamModal(true)}
            />
          )}
        </ActionButtonWrapper>
      </td>
      {deleteTeamModal && (
        <DeleteTeamModal
          openModal={deleteTeamModal}
          setOpenModal={setDeleteTeamModal}
          teamData={team}
          refreshData={refreshData}
        />
      )}
      {editTeamModal && (
        <EditTeamModal
          openModal={editTeamModal}
          setOpenModal={setEditTeamModal}
          teamData={team}
          refreshData={refreshData}
        />
      )}
    </tr>
  );
};

export default ListItem;
