import Modal from '../../../../components/templates/modal';
import { toast } from 'react-toastify';
import axiosInstance from '../../../../utils/axios';
import styled from 'styled-components';
import ModalLayout from '../../../../components/templates/modal/ModalLayout';
import FormField from '../../../../components/atoms/FormField';
import Button from '../../../../components/atoms/button';
import { useState, useRef } from 'react';

const Wrapper = styled.div`
  text-align: center;
  width: 90%;
  max-width: 400px;
  background-color: var(--primaryBackground);
  border-radius: 5px;
  overflow: hidden;

  .title {
    background-color: #fff;
    border-bottom: solid var(--accent) 5px;
    text-align: left;
    padding: 20px;

    p {
      color: var(--secondaryText);
    }
  }

  .body {
    padding: 40px;
  }

  input {
    max-width: unset;
  }

  input[type='file'] {
    background-color: transparent;
    box-shadow: unset;
  }
  .exampleBox {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    color: var(--primaryText);
    font-weight: 700;

    .iconExample {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      overflow: hidden;

      img {
        width: 100%;
      }
    }
  }
  .message {
    margin-bottom: 15px;
  }
`;

const EditSportModal = ({
  openModal,
  setOpenModal,
  refreshData,
  sportData,
}) => {
  const [errorMessage, setErrorMessage] = useState();
  const userData = localStorage.getItem('user');
  const token = JSON.parse(userData);
  const [selectedFile, setSelectedFile] = useState('');
  const [newSport, setNewSport] = useState({
    name: sportData.name,
    image: sportData.image,
  });
  const [message, setMessage] = useState('');
  const ModalLayoutRef = useRef();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewSport({
      ...newSport,
      [name]: value,
    });
  };

  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleEditSport = async (e) => {
    e.preventDefault();

    const dataForm = new FormData();
    if (sportData.name !== newSport.name) {
      dataForm.append('name', newSport.name);
    }
    if (selectedFile) {
      dataForm.append('image', selectedFile);
    }

    try {
      const { data } = await axiosInstance.patch(
        `${process.env.REACT_APP_BASE_URL}sports/${sportData.id}/`,
        dataForm
      );
      toast.success('Deporte editado');
      setOpenModal(false);
      refreshData();
    } catch (error) {
      setErrorMessage(error.response.data.detail.name);
    }
  };

  return (
    <Modal
      isOpen={openModal}
      element={
        <ModalLayout
          ref={ModalLayoutRef}
          myOwnContainer
          setOpenModal={setOpenModal}
        >
          <Wrapper>
            <div className="title">
              <h2>Editar deporte</h2>
            </div>
            <div className="body">
              <form>
                <FormField
                  type="text"
                  name="name"
                  value={newSport.name}
                  placeholder="Escribe el nombre del deporte"
                  labelText="Nombre del deporte"
                  onChange={handleChange}
                />
                <FormField
                  type="file"
                  name="name"
                  labelText="Icono para el deporte"
                  onChange={handleFileSelect}
                />
                <div className="exampleBox">
                  <div className="iconExample">
                    <img
                      src={
                        selectedFile === ''
                          ? sportData.image
                          : URL.createObjectURL(selectedFile)
                      }
                      alt=""
                    />
                  </div>
                  <p>{newSport.name}</p>
                </div>
                <div
                  className="message"
                  style={{
                    color:
                      message === 'Email de recuperación enviado'
                        ? 'green'
                        : 'red',
                  }}
                >
                  {message}
                </div>
                <Button
                  text="Editar deporte"
                  color="#fff"
                  backgroundColor="var(--primary)"
                  onClick={handleEditSport}
                />
                {errorMessage ? (
                  <p
                    style={{
                      backgroundColor: 'var(--warning)',
                      borderRadius: '5px',
                      color: '#fff',
                      fontWeight: '700',
                      marginTop: '20px',
                    }}
                  >
                    {errorMessage}
                  </p>
                ) : null}
              </form>
            </div>
          </Wrapper>
        </ModalLayout>
      }
    />
  );
};

export default EditSportModal;
