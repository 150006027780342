import { useState, useEffect } from 'react';
import styled from 'styled-components';
import InfiniteScroll from 'react-infinite-scroll-component';
import FinishedEventItem from './finishedEventItemOp2';
import axiosInstance from '../../utils/axios';
import ChallengeSkeleton from '../../components/templates/challengeSkeleton';
import Loader from '../../components/atoms/loader';

const Wrapper = styled.div`
  background-color: var(--secondaryBackground);
  margin: 20px;
  margin-top: 0;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 15px 20px -10px grey;

  a {
    font-size: 12rem;
    color: var(--secondaryText);
  }
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;

  tr {
    position: relative;
  }

  tr:hover {
    background-color: var(--primaryBackground);
    border-radius: 5px;
  }

  th,
  td {
    text-align: center;
    width: 15%;
    padding: 20px;
    font-weight: 500;

    span {
      font-weight: 300;
      color: var(--secondaryText);
    }
  }
`;

const NextEvents = () => {
  const [loading, setLoading] = useState(false);
  const [eventsList, setEventsList] = useState({
    data: [],
    detail: {},
  });
  const [pageCount, setPageCount] = useState(2);

  const getEventsList = async () => {
    setLoading(true);
    try {
      const { data } = await axiosInstance.get(
        `${process.env.REACT_APP_BASE_URL}events/?page=1&per_page=10&status=finished_pending_results&ordering=-start_datetime`
      );
      setEventsList(data);
      setLoading(false);
    } catch (error) {
      console.log(error.response.data.detail.name);
    }
  };

  const fetchData = async () => {
    try {
      const { data } = await axiosInstance.get(
        `${process.env.REACT_APP_BASE_URL}events/?page=${pageCount}&per_page=10&status=finished_pending_results&ordering=-start_datetime`
      );
      setEventsList({
        ...data,
        data: [...eventsList.data, ...data.data],
      });
      setLoading(false);
      setPageCount(pageCount + 1);
    } catch (error) {
      console.log(error.response.data.detail.name);
      setLoading(false);
    }
  };

  useEffect(() => {
    getEventsList();
  }, []);

  return (
    <Wrapper>
      <h3>Eventos sin resultados</h3>
      <p>Eventos finalizados sin resultados</p>
      {loading && (
        <div>
          <ChallengeSkeleton />
          <ChallengeSkeleton />
          <ChallengeSkeleton />
          <ChallengeSkeleton />
          <ChallengeSkeleton />
        </div>
      )}

      <InfiniteScroll
        dataLength={eventsList.data.length} //This is important field to render the next data
        next={fetchData}
        hasMore={eventsList.detail.has_more}
        loader={<Loader />}
        scrollableTarget="scrollableDiv"
        endMessage={
          <p
            style={{
              textAlign: 'center',
              color: 'var(--secondaryText)',
              marginTop: '10px',
            }}
          >
            No hay mas eventos sin resultados
          </p>
        }
      >
        <Table>
          {eventsList.data.map((sportEvent) => {
            return (
              <FinishedEventItem
                eventData={sportEvent}
                key={sportEvent.id}
                refreshData={getEventsList}
              />
            );
          })}
        </Table>
      </InfiniteScroll>
    </Wrapper>
  );
};

export default NextEvents;
