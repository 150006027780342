import { useState } from 'react';
import styled from 'styled-components';
import FormField from '../../atoms/FormField';
import Button from '../../atoms/button';
import ConfirmDeleteModal from './confirmProfileDeleteModal';

const Wrapper = styled.div`
  .textSecondary {
    color: var(--secondaryText);
  }

  & > div {
    margin-bottom: 40px;
  }

  ul {
    list-style: disc;
    padding-left: 20px;
  }

  div:last-child {
    margin-bottom: 0;
  }
`;

const DeleteAccount = () => {
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState({
    nickname: '',
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setDeleteConfirmation({
      ...deleteConfirmation,
      [name]: value,
    });
  };

  return (
    <Wrapper>
      <div>
        <h2>Eliminar cuenta</h2>
        <p className="textSecondary">
          Tu cuenta quedara desactivada por unos días y luego pasara a ser
          eliminada, ten en cuenta que:
        </p>
        <ul className="textSecondary">
          <li>No debes tener saldo disponible de GCC.</li>
          <li>No debes tener ningún reto activo.</li>
        </ul>
      </div>
      <div>
        <FormField
          type="text"
          name="nickname"
          value={deleteConfirmation.nickname}
          placeholder="Escribe tu apodo para confirmar la eliminación"
          labelText="Escribe tu apodo para confirmar la eliminación"
          onChange={handleChange}
        />
      </div>
      <div>
        <Button
          text="Eliminar cuenta"
          color="#fff"
          backgroundColor="var(--warning)"
          onClick={(e) => {
            e.preventDefault();
            setOpenConfirmModal(true);
          }}
        />
      </div>
      {openConfirmModal && (
        <ConfirmDeleteModal
          openModal={openConfirmModal}
          setOpenModal={setOpenConfirmModal}
          // refreshData={getRolsList}
        />
      )}
    </Wrapper>
  );
};

export default DeleteAccount;
