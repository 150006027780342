import { useRef, useState, useEffect, useContext } from 'react';
import { AppContext } from '../../contexts/AppContext';
import styled from 'styled-components';
import UserChallenges from '../dashboard/upcomingEvents/userChallenges';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Button from '../../components/atoms/button';
import ActionButton from '../../components/atoms/actionButtons';
import BlockIcon from '@mui/icons-material/Block';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import EditIcon from '@mui/icons-material/Edit';
import axiosInstance from '../../utils/axios';
import { returnTime } from '../../utils/formatDate';
import DeleteEventModal from '../dashboard/upcomingEvents/disableEvent';
import EventResults from '../../components/templates/results';
import EditEventModal from '../dashboard/upcomingEvents/editEventModal';

const Wrapper = styled.div`
  width: 100%;
  padding: 5px;

  .hide {
    display: none !important;
  }

  .event-box {
    border-radius: 5px;
    overflow: hidden;
  }

  .event-header {
    background-color: var(--secondary);
    color: #293641;
    padding: 10px;
    display: grid;
    grid-template-columns: 1fr 3fr 5fr 3fr 3fr;
    border-radius: 5px;

    .header-item {
      font-weight: 500;
      display: flex;
      gap: 10px;
      justify-content: center;
      align-items: center;
      text-align: center;

      span {
        font-weight: 300;
      }
    }

    @media (max-width: 800px) {
      display: none;
    }
  }

  .event-info {
    background-color: var(--secondaryBackground);
    padding: 10px;

    p {
      font-weight: 500;
    }

    .challengesUsers {
      padding: 20px;

      & > div {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;

        @media (max-width: 800px) {
          width: 100%;
          grid-template-columns: 1fr;
        }
      }

      a {
        text-decoration: underline;
        font-weight: 300;
        color: var(--secondaryText);
      }
    }
  }
`;

const TeamsWrapper = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
  margin-top: 5px;

  .team {
    display: flex;
    gap: 10px;
    align-items: center;

    &>div {
      flex: 1 0 20px;
    }
  }
}
`;

const Icon = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const WrapperMobile = styled.div`
  background-color: var(--secondaryBackground);
  text-align: center;
  overflow: hidden;

  .challengeData {
    padding: 20px;
    & > * {
      margin: 10px 0;
    }

    .league {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  button {
    padding: 10px;
    background-color: #39cb6b;
  }

  @media (min-width: 800px) {
    display: none;
  }
`;

const ActiveChallengesItem = ({ eventData, refreshData }) => {
  const [challengeList, getChallengeList] = useState([]);
  const [deleteEventModal, setDeleteEventModal] = useState(false);
  const [editEventModal, setEditEventModal] = useState(false);
  const [eventResultsModal, setEventResultsModal] = useState(false);
  const ChallengerUsers = useRef();
  const date = new Date(eventData.start_datetime);
  const context = useContext(AppContext);

  const UnhideChallengers = () => {
    ChallengerUsers.current.classList.toggle('hide');
    /* getChallengesList(); */
  };

  const getChallengesList = async () => {
    try {
      const { data } = await axiosInstance.get(
        `${process.env.REACT_APP_BASE_URL}challenges/?event=${+eventData.id}`
      );
      getChallengeList(data);
    } catch (error) {
      console.log(error.response.data.detail.name);
    }
  };

  useEffect(() => {
    getChallengesList();
  }, []);

  return (
    <Wrapper>
      <div className="event-box">
        <div className="event-header">
          <div className="header-item">
            <Icon>
              <img src={eventData.sport.image} alt="" />
            </Icon>
          </div>
          <div className="header-item">
            <Icon>
              <img src={eventData.league.image} alt="" />
            </Icon>
            {eventData.league.name}
          </div>
          <div className="header-item">
            <TeamsWrapper>
              <div className="team">
                <p>{eventData.team1.name}</p>
                <Icon>
                  <img src={eventData.team1.image} alt="" />
                </Icon>
              </div>
              <p>vs</p>
              <div className="team">
                <Icon>
                  <img src={eventData.team2.image} alt="" />
                </Icon>
                <p>{eventData.team2.name}</p>
              </div>
            </TeamsWrapper>
          </div>
          <div className="header-item">
            <p>
              {returnTime(eventData.start_datetime, context.store.timezone, {
                dateStyle: 'medium',
              })}
              <br />
              <span>
                {returnTime(eventData.start_datetime, context.store.timezone, {
                  timeStyle: 'short',
                })}
              </span>
            </p>
          </div>
          <div className="header-item" style={{ justifyContent: 'end' }}>
            <ActionButton
              text={
                <EmojiEventsIcon
                  style={{ width: '25px', height: '25px', color: '#fff' }}
                />
              }
              color="#fff"
              backgroundColor="var(--primary)"
              onClick={() => setEventResultsModal(true)}
            />
            <ActionButton
              text={
                <EditIcon
                  style={{ width: '25px', height: '25px', color: '#fff' }}
                />
              }
              color="#fff"
              backgroundColor="#5bc6e8"
              onClick={() => setEditEventModal(true)}
            />
            <ActionButton
              text={
                <BlockIcon
                  style={{ width: '25px', height: '25px', color: '#fff' }}
                />
              }
              color="#fff"
              backgroundColor="#ff5353"
              onClick={() => setDeleteEventModal(true)}
            />
            <ActionButton
              text={
                <ArrowDropDownIcon
                  style={{ width: '25px', height: '25px', color: '#fff' }}
                />
              }
              color="#fff"
              backgroundColor="#324250"
              onClick={UnhideChallengers}
            />
          </div>
        </div>
        <WrapperMobile>
          <div className="challengeData">
            <div className="league">
              <Icon>
                <img src={eventData?.sport.image} alt="" />
              </Icon>
              <p>{eventData?.league.name}</p>
              <Icon>
                <img src={eventData?.league.image} alt="" />
              </Icon>
            </div>
            <TeamsWrapper>
              <div className="team">
                <p>{eventData?.team1.name} </p>
                <Icon>
                  <img src={eventData?.team1.image} alt="" />
                </Icon>
              </div>
              <p>vs</p>
              <div className="team">
                <Icon>
                  <img src={eventData?.team2.image} alt="" />
                </Icon>
                <p>{eventData?.team2.name} </p>
              </div>
            </TeamsWrapper>
            <p>
              {returnTime(eventData.start_datetime, context.store.timezone, {
                dateStyle: 'medium',
              })}
              {' • '}
              <span style={{ color: 'var(--secondaryText)' }}>
                {returnTime(eventData.start_datetime, context.store.timezone, {
                  timeStyle: 'short',
                })}
              </span>
            </p>
          </div>
          <div
            className="buttonsBox"
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '10px',
            }}
          >
            <ActionButton
              text={
                <EmojiEventsIcon
                  style={{ width: '25px', height: '25px', color: '#fff' }}
                />
              }
              color="#fff"
              backgroundColor="var(--primary)"
              onClick={() => setEventResultsModal(true)}
            />
            <ActionButton
              text={
                <EditIcon
                  style={{ width: '25px', height: '25px', color: '#fff' }}
                />
              }
              color="#fff"
              backgroundColor="#5bc6e8"
              onClick={() => setEditEventModal(true)}
            />
            <ActionButton
              text={
                <BlockIcon
                  style={{ width: '25px', height: '25px', color: '#fff' }}
                />
              }
              color="#fff"
              backgroundColor="#ff5353"
              onClick={() => setDeleteEventModal(true)}
            />
            <Button
              text="Acepta uno" /* {
                <ArrowDropDownIcon
                  style={{ width: '25px', height: '25px', color: '#fff' }}
                />
              } */
              color="#fff"
              backgroundColor="#39cb6b"
              onClick={UnhideChallengers}
            />
          </div>
        </WrapperMobile>
        <div className="event-info">
          <div className="challengesUsers hide" ref={ChallengerUsers}>
            <p>Retos creados para este evento:</p>
            <div>
              {challengeList.length === 0 ? (
                <p style={{ color: 'var(--secondaryText)', marginTop: '10px' }}>
                  No hay retos activos para este evento,{' '}
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                    style={{ color: 'var(--accent)', fontWeight: '700' }}
                  >
                    Crea uno
                  </button>
                </p>
              ) : (
                challengeList
                  .filter((item) => {
                    return item.status != 'active';
                  })
                  .map((challenge) => {
                    return (
                      <UserChallenges
                        challengeData={challenge}
                        key={challenge.id}
                      />
                    );
                  })
              )}
              {/* <a href="-">Ver 4 mas</a> */}
            </div>
            {/*  <div>
              <p>Retos creados por otros usuarios para este evento:</p>
              <UserChallenges />
              <UserChallenges />
              <UserChallenges />
              <a href="-">Ver 132 mas</a>
            </div> */}
          </div>
          <p style={{ textAlign: 'right', fontWeight: '300' }}>
            {`Hay ${
              challengeList.filter((item) => {
                return item.status != 'active';
              }).length
            } ${
              challengeList.filter((item) => {
                return item.status != 'active';
              }).length === 1
                ? 'reto'
                : 'retos'
            } para este evento`}
          </p>
        </div>
      </div>
      {deleteEventModal && (
        <DeleteEventModal
          openModal={deleteEventModal}
          setOpenModal={setDeleteEventModal}
          eventData={eventData}
          refreshData={refreshData}
        />
      )}
      {eventResultsModal && (
        <EventResults
          openModal={eventResultsModal}
          setOpenModal={setEventResultsModal}
          eventData={eventData}
          refreshData={refreshData}
        />
      )}
      {editEventModal && (
        <EditEventModal
          openModal={editEventModal}
          setOpenModal={setEditEventModal}
          eventData={eventData}
          refreshData={refreshData}
        />
      )}
    </Wrapper>
  );
};

export default ActiveChallengesItem;
