import { useState } from 'react';
import styled from 'styled-components';
import CheckIcon from '@mui/icons-material/Check';
import BlockIcon from '@mui/icons-material/Block';
import EditIcon from '@mui/icons-material/Edit';
import ActionButton from '../../../atoms/actionButtons';
import DeleteSportModal from './disableSport';
import EditSportModal from './editSportModal';

const ActionButtonWrapper = styled.div`
  display: flex;
  gap: 10px;
  justify-content: center;
`;

const Button = styled.button`
  width: 30px;
  height: 30px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    filter: opacity(50%);
  }
`;

const EditButton = styled(Button)`
  background-color: #5bc6e8;
`;

const DeleteButton = styled(Button)`
  background-color: #ff5353;
`;

const Icon = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;

  img {
    width: 100%;
  }
`;

const ListItem = ({ sport, refreshData }) => {
  const [deleteSportModal, setDeleteSportModal] = useState(false);
  const [editSportModal, setEditSportModal] = useState(false);
  return (
    <tr style={{ filter: `opacity(${sport.status ? '100%' : '30%'})` }}>
      <td style={{ fontWeight: '700' }}>{sport.name}</td>
      <td style={{ display: 'flex', justifyContent: 'center' }}>
        <Icon>
          <img
            src={
              sport.image !== ''
                ? sport.image
                : 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTH4d1ldfiI0npUaDbUXnRHjumUJmSpIaKErA&usqp=CAU'
            }
            alt=""
          />
        </Icon>
      </td>
      <td>
        <ActionButtonWrapper>
          <ActionButton
            text={
              <EditIcon
                style={{ width: '25px', height: '25px', color: '#fff' }}
              />
            }
            color="#fff"
            backgroundColor="#5bc6e8"
            onClick={() => setEditSportModal(true)}
            disabled={!sport.status}
          />
          {sport.status ? (
            <ActionButton
              text={
                <BlockIcon
                  style={{ width: '25px', height: '25px', color: '#fff' }}
                />
              }
              color="#fff"
              backgroundColor="#ff5353"
              onClick={() => setDeleteSportModal(true)}
            />
          ) : (
            <ActionButton
              text={
                <CheckIcon
                  style={{ width: '25px', height: '25px', color: '#fff' }}
                />
              }
              color="#fff"
              backgroundColor="#46c791"
              onClick={() => setDeleteSportModal(true)}
            />
          )}
        </ActionButtonWrapper>
      </td>
      {deleteSportModal && (
        <DeleteSportModal
          openModal={deleteSportModal}
          setOpenModal={setDeleteSportModal}
          sportData={sport}
          refreshData={refreshData}
        />
      )}
      {editSportModal && (
        <EditSportModal
          openModal={editSportModal}
          setOpenModal={setEditSportModal}
          sportData={sport}
          refreshData={refreshData}
        />
      )}
    </tr>
  );
};

export default ListItem;
