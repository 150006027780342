import { useState } from 'react';
import styled from 'styled-components';
import BlockIcon from '@mui/icons-material/Block';
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
import ActionButton from '../../../atoms/actionButtons';
import DeleteLeagueModal from './disableLeague';
import EditLeagueModal from './editLeagueModal';

const ActionButtonWrapper = styled.div`
  display: flex;
  gap: 10px;
  justify-content: center;
`;

const Button = styled.button`
  width: 30px;
  height: 30px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    filter: opacity(50%);
  }
`;

const EditButton = styled(Button)`
  background-color: #5bc6e8;
`;

const DeleteButton = styled(Button)`
  background-color: #ff5353;
`;

const Icon = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;

  img {
    width: 100%;
  }
`;

const ListItem = ({ league, refreshData }) => {
  const [deleteLeagueModal, setDeleteLeagueModal] = useState(false);
  const [editLeagueModal, setEditLeagueModal] = useState(false);
  return (
    <tr style={{ filter: `opacity(${league.status ? '100%' : '30%'})` }}>
      <td style={{ fontWeight: '700' }}>{league.name}</td>
      <td>{league.sport.name}</td>
      <td style={{ display: 'flex', justifyContent: 'center' }}>
        <Icon>
          <img
            src={
              league.image !== ''
                ? league.image
                : 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTH4d1ldfiI0npUaDbUXnRHjumUJmSpIaKErA&usqp=CAU'
            }
            alt=""
          />
        </Icon>
      </td>
      <td>
        <ActionButtonWrapper>
          <ActionButton
            text={
              <EditIcon
                style={{ width: '25px', height: '25px', color: '#fff' }}
              />
            }
            color="#fff"
            backgroundColor="#5bc6e8"
            onClick={() => setEditLeagueModal(true)}
            disabled={!league.status}
          />
          {league.status ? (
            <ActionButton
              text={
                <BlockIcon
                  style={{ width: '25px', height: '25px', color: '#fff' }}
                />
              }
              color="#fff"
              backgroundColor="#ff5353"
              onClick={() => setDeleteLeagueModal(true)}
            />
          ) : (
            <ActionButton
              text={
                <CheckIcon
                  style={{ width: '25px', height: '25px', color: '#fff' }}
                />
              }
              color="#fff"
              backgroundColor="#46c791"
              onClick={() => setDeleteLeagueModal(true)}
            />
          )}
        </ActionButtonWrapper>
      </td>
      {deleteLeagueModal && (
        <DeleteLeagueModal
          openModal={deleteLeagueModal}
          setOpenModal={setDeleteLeagueModal}
          leagueData={league}
          refreshData={refreshData}
        />
      )}
      {editLeagueModal && (
        <EditLeagueModal
          openModal={editLeagueModal}
          setOpenModal={setEditLeagueModal}
          leagueData={league}
          refreshData={refreshData}
        />
      )}
    </tr>
  );
};

export default ListItem;
