import { useEffect, useState } from 'react';
import styled from 'styled-components';
import Button from '../../components/atoms/button';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../utils/axios';

const Wrapper = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 20px;

  .caja {
    border-radius: 5px;
    box-shadow: 0 15px 20px -10px grey;
    padding: 20px;
    width: 100%;
    max-width: 300px;
    text-align: center;

    h2 {
      text-align: left;
      margin-bottom: 5px;
      color: var(--primary);
      font-size: 16px;
    }
  }

  .buttons {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
  }
`;

const SideComponent = () => {
  let navigate = useNavigate();
  const [userList, setUserList] = useState([]);
  const [blockedUsersList, setBlockedUsersList] = useState([]);
  const [challengesList, setChallengesList] = useState([]);
  const [siteConfigData, setSiteConfigData] = useState({
    gcc_to_currency: 1.0,
  });

  const getUserData = async () => {
    try {
      const { data } = await axiosInstance.get(
        `${process.env.REACT_APP_BASE_URL}site_config/`
      );
      setSiteConfigData(data);
    } catch (error) {
      // toast.success('Hubo un error aceptando el reto');
      console.log(error.response.data.detail.name);
    }
  };

  const getChallengesList = async () => {
    try {
      const { data } = await axiosInstance.get(
        `${process.env.REACT_APP_BASE_URL}challenges/`
      );
      setChallengesList(data);
    } catch (error) {
      console.log(error.response.data.detail.name);
    }
  };

  const getUsersList = async () => {
    try {
      const { data } = await axiosInstance.get(
        `${process.env.REACT_APP_BASE_URL}auth/users/list/`
      );
      setUserList(data);
    } catch (error) {
      console.log(error.response.data.detail.name);
    }
  };

  const getBlockedUsersList = async () => {
    try {
      const { data } = await axiosInstance.get(
        `${process.env.REACT_APP_BASE_URL}client_management/list_blocked_clients/`
      );
      setBlockedUsersList(data);
    } catch (error) {
      console.log(error.response.data.detail.name);
    }
  };

  useEffect(() => {
    getUserData();
    getUsersList();
    getBlockedUsersList();
    getChallengesList();
  }, []);
  return (
    <Wrapper>
      <div className="caja coin">
        <h2>GoChallenge Coin</h2>
        <p
          style={{
            fontSize: '24px',
            fontWeight: '700',
            color: 'var(--warning)',
          }}
        >
          1 GCC = {siteConfigData.gcc_to_currency}$
        </p>
        <hr />
        <Button
          text="Cambiar precio"
          color="#fff"
          backgroundColor="#324250"
          onClick={() => navigate('/account/gcc-manage')}
        />
      </div>
      <div className="caja users">
        <h2>
          Usuarios
          <br />
          <span style={{ fontSize: '12px' }}>
            <span style={{ color: '#39CB6B' }}>Activos</span> /{' '}
            <span style={{ color: 'var(--warning)' }}>Inactivos</span> /{' '}
            <span style={{ color: 'var(--primary)' }}>bloqueados</span>
          </span>
        </h2>
        <p
          style={{
            fontSize: '24px',
            fontWeight: '700',
            color: 'var(--accent)',
          }}
        >
          {userList.length + blockedUsersList.length}
        </p>
        <p style={{ fontWeight: '700' }}>
          <span style={{ color: '#39CB6B' }}>
            {
              userList.filter((user) => {
                return user.status === 'active';
              }).length
            }
          </span>{' '}
          /{' '}
          <span style={{ color: 'var(--warning)' }}>
            {
              userList.filter((user) => {
                return user.status === 'inactive';
              }).length
            }
          </span>{' '}
          /{' '}
          <span style={{ color: 'var(--primary)' }}>
            {blockedUsersList.length}
          </span>
        </p>
        <hr />
        <a href="/manage-clients">Ver mas</a>
      </div>
      <div className="caja challenges">
        <h2>Retos actuales</h2>
        <p
          style={{
            fontSize: '24px',
            fontWeight: '700',
            color: 'var(--accent)',
          }}
        >
          {challengesList.length}
        </p>
        <hr />
        <a href="">Ver mas</a>
      </div>
      <div className=" caja buttons">
        <Button
          text="Cargar CSV de eventos"
          color="#fff"
          backgroundColor="#324250"
          onClick={() => navigate('/load-events')}
        />
        {/* <Button
          text="Cargar CSV de resultados"
          color="#fff"
          backgroundColor="#324250"
        /> */}
      </div>
    </Wrapper>
  );
};

export default SideComponent;
