import { useState, useEffect } from 'react';
import styled from 'styled-components';
import ProfileImage from '../../../assets/images/profile.jpg';
import FormField from '../../atoms/FormField';
import Button from '../../atoms/button';
import axiosInstance from '../../../utils/axios';
import { toast } from 'react-toastify';

const Wrapper = styled.div`
  .textSecondary {
    color: var(--secondaryText);
  }

  & > div {
    margin-bottom: 40px;
  }

  div:last-child {
    margin-bottom: 0;
  }
`;

const Form = styled.form`
  .profilePicture {
    display: flex;
    align-items: center;

    span {
      font-size: 12rem;
    }

    input {
      width: 0.1px;
      height: 0.1px;
    }

    input::before {
      content: '+';
      position: absolute;
      cursor: pointer;
      font-weight: 700;
      font-size: 50rem;
      color: var(--primary);
      top: 0;
      left: 0;
      width: 60px;
      height: 60px;
      text-align: center;
      line-height: 60px;
    }

    .profile_picture {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      position: relative;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  div {
    flex-direction: row;

    label {
      width: 200px;
    }

    input {
      width: 100%;
      max-width: 400px;
    }
  }
`;

const InputFileWrapper = styled.div`
  display: flex;
  width: 400px;

  button {
    color: #ff5353;
    font-weight: 700;
    font-size: 12rem;
    margin-left: 20px;
  }
`;

const Profile = () => {
  const [profileData, setProfileData] = useState({
    id: '',
    avatar: null,
    preferences: [],
    email: '',
    username: '',
    first_name: '',
    last_name: '',
    date_of_birth: '',
    phone: '',
    personal_description: '',
    status: '',
    is_email_verified: null,
    e_wallet: '',
  });
  const [updatedData, setUpdatedData] = useState({});
  const [selectedFile, setSelectedFile] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUpdatedData({
      ...updatedData,
      [name]: value,
    });
  };

  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const getUserData = async () => {
    try {
      const { data } = await axiosInstance.get(
        `${process.env.REACT_APP_BASE_URL}auth/users/current/`
      );
      setProfileData(data);
    } catch (error) {
      // toast.success('Hubo un error aceptando el reto');
      console.log(error.response.data.detail.name);
    }
  };

  const submitUserUpdate = async (e) => {
    const dataForm = new FormData();

    if (selectedFile) {
      dataForm.append('avatar', selectedFile);

      for (var key in updatedData) {
        dataForm.append(key, updatedData[key]);
      }
    }

    e.preventDefault();
    try {
      const { data } = await axiosInstance.patch(
        `${process.env.REACT_APP_BASE_URL}auth/users/current/update/`,
        selectedFile ? dataForm : updatedData
      );
      toast.success('Datos actualizados con exito');
    } catch (error) {
      if (error.response.data.phone) {
        toast.warn(`Telefono: ${error.response.data.phone[0]}`);
      } else if (error.response.data.username) {
        toast.warn(error.response.data.username[0]);
      } else {
        toast.error('Ocurrio un error inesperado');
      }
      // console.log(error.response.data.username[0]);
    }
  };

  useEffect(() => {
    getUserData();
  }, []);

  return (
    <Wrapper>
      <div>
        <h2>Editar perfil</h2>
        <p className="textSecondary">
          Actualiza tu foto e información general aquí
        </p>
      </div>
      <div>
        <Form>
          <div className="profilePicture">
            <label htmlFor="profilePicture">
              Fotografía <span className="textSecondary">(Max 3Mb)</span>
            </label>
            <InputFileWrapper>
              <div className="profile_picture" style={{ flex: '0 0 60px' }}>
                <img
                  src={
                    selectedFile != ''
                      ? URL.createObjectURL(selectedFile)
                      : profileData.avatar
                  }
                  alt=""
                />
                <input
                  type="file"
                  name="profilePicture"
                  onChange={handleFileSelect}
                />
              </div>
              {/* <button>Eliminar</button> */}
            </InputFileWrapper>
          </div>
          <FormField
            type="text"
            name="first_name"
            value={updatedData.first_name || profileData.first_name}
            placeholder="Escribe tu nombre"
            labelText="Nombre"
            onChange={handleChange}
          />
          <FormField
            type="text"
            name="last_name"
            value={updatedData.last_name || profileData.last_name}
            placeholder="Escribe tu apellido"
            labelText="Apellido"
            onChange={handleChange}
          />
          <FormField
            type="date"
            name="date_of_birth"
            value={updatedData.date_of_birth || profileData.date_of_birth}
            labelText="Fecha de nacimiento"
            onChange={handleChange}
          />
          {/* <FormField
            type="text"
            name="mail"
            value={profileData.mail}
            placeholder="Escribe tu correo electrónico"
            labelText="Correo electrónico"
            onChange={handleChange}
          /> */}
          <FormField
            type="number"
            name="phone"
            value={updatedData.phone || profileData.phone}
            placeholder="Escribe tu numero telefónico"
            labelText="Teléfono (Max. 10 digitos)"
            onChange={handleChange}
          />
        </Form>
      </div>
      <div>
        <Button
          text="Guardar cambios"
          color="#fff"
          backgroundColor="#324250"
          onClick={submitUserUpdate}
        />
      </div>
    </Wrapper>
  );
};

export default Profile;
