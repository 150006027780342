import styled from 'styled-components';
import Modal from '../../components/templates/modal';
import ModalLayout from '../../components/templates/modal/ModalLayout';
import Button from '../../components/atoms/button';
import { useState, useRef, useEffect } from 'react';
import axiosInstance from '../../utils/axios';
import FormField from '../../components/atoms/FormField';
import { toast } from 'react-toastify';
import UserClients from '.';

const Wrapper = styled.div`
  text-align: center;
  width: 90%;
  max-width: 400px;
  background-color: var(--primaryBackground);
  border-radius: 5px;
  overflow: hidden;

  .title {
    background-color: #fff;
    text-align: left;
    padding: 20px;

    p {
      color: var(--secondaryText);
    }
  }

  .body {
    padding: 20px;
  }

  input {
    max-width: unset;
  }

  input[type='file'] {
    background-color: transparent;
    box-shadow: unset;
  }
  .exampleBox {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    color: var(--primaryText);
    font-weight: 700;

    .iconExample {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      overflow: hidden;

      img {
        width: 100%;
      }
    }
  }
  .message {
    margin-bottom: 15px;
  }
`;

const Form = styled.form`
  .profilePicture {
    display: flex;
    align-items: center;

    span {
      font-size: 12rem;
    }

    input {
      width: 0.1px;
      height: 0.1px;
    }

    label {
      font-weight: 700;
    }

    input::before {
      content: '+';
      position: absolute;
      cursor: pointer;
      font-weight: 700;
      font-size: 50rem;
      color: var(--primary);
      top: 0;
      left: 0;
      width: 60px;
      height: 60px;
      text-align: center;
      line-height: 60px;
    }

    .profile_picture {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      position: relative;
      overflow: hidden;

      img {
        width: 100%;
      }
    }
  }

  div {
    flex-direction: row;
    align-items: center;

    label {
      width: 200px;
      text-align: left;
    }

    input {
      width: 100%;
      max-width: 400px;
    }
  }
`;

const Dropdown = styled.div`
  display: flex;
  padding-bottom: 20px;

  label {
    font-weight: 700;
  }

  select {
    height: 30px;
    width: 100%;
    border-radius: 10px;
    background-color: var(--secondaryBackground);
    box-shadow: 0px 4px 4px -4px var(--shadow);
    border: none;
    font-size: 13rem;
    padding: 0 10px;
    margin-top: 5px;
    max-width: 400px;
  }
`;

const AddNewAdminUser = ({ openModal, setOpenModal, user, refreshData }) => {
  const ModalLayoutRef = useRef();
  const [message, setMessage] = useState('');
  const [rolesList, setRolesList] = useState([]);
  const [userData, setUserData] = useState({
    email: '',
    password: '',
    username: '',
    user_role: '',
  });

  const createUser = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axiosInstance.post(
        `${process.env.REACT_APP_BASE_URL}auth/users/create/`,
        userData
      );
      toast.success('Datos actualizados con exito');
      setOpenModal(false);
    } catch (error) {
      console.log(error.response.data);
      toast.warn(`Error`);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData({
      ...userData,
      [name]: value,
    });
  };

  const getRolsList = async () => {
    try {
      const { data } = await axiosInstance.get(
        `${process.env.REACT_APP_BASE_URL}auth/user_roles/`
      );

      setRolesList(data.results);
    } catch (error) {
      console.log(error.response.data.detail.name);
    }
  };

  useEffect(() => {
    getRolsList();
  }, []);

  return (
    <Modal
      isOpen={openModal}
      element={
        <ModalLayout
          ref={ModalLayoutRef}
          myOwnContainer
          setOpenModal={setOpenModal}
        >
          <Wrapper>
            <div className="title">
              <h2>Agrego nuevo administrador</h2>
            </div>
            <div className="body">
              <Form>
                <FormField
                  type="text"
                  name="email"
                  value={userData.email}
                  placeholder="Escribe el correo"
                  labelText="Email"
                  onChange={handleChange}
                />
                <FormField
                  type="text"
                  name="password"
                  value={userData.password}
                  placeholder="Escribe la clave"
                  labelText="Password"
                  onChange={handleChange}
                />
                <FormField
                  type="text"
                  name="username"
                  value={userData.username}
                  placeholder="Escribe el username"
                  labelText="Username"
                  onChange={handleChange}
                />

                <Dropdown>
                  <label htmlFor="league">Rol</label>
                  <select
                    name="user_role"
                    required
                    value={userData.user_role}
                    onChange={handleChange}
                  >
                    <option value="">Selecciona el rol</option>
                    {rolesList.map((rol) => {
                      return (
                        <option value={rol.id} key={rol.id}>
                          {rol.name}
                        </option>
                      );
                    })}
                  </select>
                </Dropdown>
                <div>
                  <Button
                    text="Guardar cambios"
                    color="#fff"
                    backgroundColor="#324250"
                    onClick={createUser}
                  />
                </div>
              </Form>
            </div>
          </Wrapper>
        </ModalLayout>
      }
    />
  );
};

export default AddNewAdminUser;
