import Modal from '../../components/templates/modal';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import ModalLayout from '../../components/templates/modal/ModalLayout';
import FormField from '../../components/atoms/FormField';
import Button from '../../components/atoms/button';
import { useState, useRef, useEffect } from 'react';
import axiosInstance from '../../utils/axios';

import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="large" />;
const checkedIcon = <CheckBoxIcon fontSize="large" />;

const CustomAutocomplete = styled(Autocomplete)`
  margin-top: 5px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 4px 4px -4px var(--shadow);
  color: red;

  fieldset {
    border-color: transparent;
  }

  *,
  li {
    font-size: 16rem !important;
  }
`;

const CustomCheckbox = styled(Checkbox)`
  width: 30px;
  height: 30px;
  * {
    font-size: 24rem !important;
  }
`;

const Wrapper = styled.div`
  text-align: center;
  width: 90%;
  max-width: 400px;
  background-color: var(--primaryBackground);
  border-radius: 5px;
  overflow: hidden;

  .title {
    background-color: #fff;
    border-bottom: solid var(--accent) 5px;
    text-align: left;
    padding: 20px;

    p {
      color: var(--secondaryText);
    }
  }

  .body {
    padding: 20px 40px 40px 40px;
  }

  input {
    max-width: unset;
  }

  input[type='file'] {
    background-color: transparent;
    box-shadow: unset;
  }
  .exampleBox {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    color: var(--primaryText);
    font-weight: 700;

    .iconExample {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      overflow: hidden;

      img {
        width: 100%;
      }
    }
  }
  .message {
    margin-bottom: 15px;
  }
`;

const Dropdown = styled.div`
  padding-top: 20px;
  label {
    font-weight: 700;
  }

  select {
    height: 30px;
    width: 100%;
    border-radius: 10px;
    background-color: var(--secondaryBackground);
    box-shadow: 0px 4px 4px -4px var(--shadow);
    border: none;
    font-size: 13rem;
    padding: 0 10px;
    margin-top: 5px;
    max-width: 400px;
  }
`;

const AddEventModal = ({ openModal, setOpenModal, refreshData }) => {
  const leaguesRef = useRef([]);
  const [selectedFile, setSelectedFile] = useState('');
  const [sportsList, setSportsList] = useState([{ name: 'Cargando deportes' }]);
  const [leaguesList, setLeaguesList] = useState([{ name: 'Cargando ligas' }]);
  const [teamsList, setTeamsList] = useState([{ name: 'Cargando equipos' }]);
  const [newTeam, setNewTeam] = useState({
    start_datetime: '',
    sport: '',
    league: '',
    team1: '',
    team2: '',
    timezone: '-06:00',
  });
  const [message, setMessage] = useState('');
  const ModalLayoutRef = useRef();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewTeam({
      ...newTeam,
      [name]:
        name === 'start_datetime' ? `${value}:00${newTeam.timezone}` : value,
    });
  };

  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleCreateEvent = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axiosInstance.post(
        `${process.env.REACT_APP_BASE_URL}events/`,
        newTeam
      );
      toast.success('Evento creado');
      setOpenModal(false);
      refreshData();
    } catch (error) {
      setMessage(error.response.data.detail.error[0]);
    }
  };

  const getSportsList = async () => {
    try {
      const { data } = await axiosInstance.get(
        `${process.env.REACT_APP_BASE_URL}sports/?status=1/`
      );
      setSportsList(data);
    } catch (error) {
      console.log(error.response.data.detail.name);
    }
  };

  const getLeaguesList = async () => {
    try {
      const { data } = await axiosInstance.get(
        `${process.env.REACT_APP_BASE_URL}leagues/?status=1/`
      );
      setLeaguesList(data);
    } catch (error) {
      console.log(error.response.data.detail.name);
    }
  };

  const getTeamsList = async () => {
    try {
      const { data } = await axiosInstance.get(
        `${process.env.REACT_APP_BASE_URL}teams/?status=1/`
      );
      setTeamsList(data);
    } catch (error) {
      console.log(error.response.data.detail.name);
    }
  };

  const timezones = [
    {
      zone: '(UTC-12:00) Baker (Island), Howland Island (Island)',
      value: '-12:00',
    },
    { zone: '(UTC-11:00) Midway Islands, Niue, Samoa', value: '-11:00' },
    {
      zone: '(UTC-10:00) Tahiti, Rarotonga, Hawaii, Adak Island',
      value: '-10:00',
    },
    {
      zone: '(UTC-09:00) Gambier Islands, Alaska, Juneau, Anchorage, Metlakatla, Sitka',
      value: '-09:00',
    },
    { zone: '(UTC-08:00) Vancouver, Los Angeles', value: '-08:00' },
    {
      zone: '(UTC-07:00) Chihuahua City, Creston, Dawson City, Mazatlán, Arizona City, Whitehorse, Alberta, Denver',
      value: '-07:00',
    },
    {
      zone: '(UTC-06:00) Mexico City, Monterrey, Costa Rica, Guatemala, Chicago, Iowa',
      value: '-06:00',
    },
    {
      zone: '(UTC-05:00) Thunder Bay, Panama, New York, Toronto, Havana, Bogotá, Lima, Quito',
      value: '-05:00',
    },
    {
      zone: '(UTC-04:00) Georgetown, Barbados, Bermuda, Dominican Republic, Puerto Rico, La Paz, Venezuela',
      value: '-04:00',
    },
    { zone: '(UTC-03:30) Newfoundland', value: '-03:30' },
    {
      zone: '(UTC-03:00) Brasília, Buenos Aires, Miquelon, Greenland, Santiago',
      value: '-03:00',
    },
    { zone: '(UTC-02:00) Fernando de Noronha, South Georgia', value: '-02:00' },
    { zone: '(UTC-01:00) Cape Verde, Azores', value: '-01:00' },
    {
      zone: '(UTC+00:00) Dakar, Iceland, Lisbon, Monrovia, Dublin, Edinburgh, London',
      value: '+00:00',
    },
    {
      zone: '(UTC+01:00) Algeria, Porto-Novo, Casablanca, Prague, Amsterdam, Berlin, City of Brussels, Vienna, Copenhagen, Madrid, Paris, Rome, Stockholm, Warsaw',
      value: '+10:00',
    },
    {
      zone: '(UTC+02:00) Kaliningrad, Egypt, South Africa, Cyprus, Sudan, Jerusalem',
      value: '+20:00',
    },
    {
      zone: '(UTC+03:00) Moscow Time, Kenya, Tanzania, Somalia, Madagascar, Iraq, Yemen, Kuwait, Saudi Arabia, Qatar',
      value: '+03:00',
    },
    { zone: '(UTC+03:30) Tehran', value: '+03:30' },
    {
      zone: '(UTC+04:00) UAE, Azerbaijan, Armenia, Oman, Samara, Izhevsk, Abkhazia, Georgia',
      value: '+04:00',
    },
    { zone: '(UTC+04:30) Afghanistan', value: '+04:30' },
    {
      zone: '(UTC+05:00) Maldives, Turkmenistan, Yekaterinburg Time, Kyzylorda, Uzbekistan, Kazakhstan, Islamabad, Karachi',
      value: '+05:00',
    },
    { zone: '(UTC+05:30) India, Sri Lanka', value: '+05:30' },
    { zone: '(UTC+05:45) Nepal', value: '+05:45' },
    {
      zone: '(UTC+06:00) Omsk Time, Bangladesh, Kazakhstan (Astana), Kyrgyzstan',
      value: '+06:00',
    },
    { zone: '(UTC+06:30) Myanmar', value: '+06:30' },
    {
      zone: '(UTC+07:00) Krasnoyarsk Time, Novosibirsk, Kemerovo, Bangkok, Tomsk, Barnaul, Jakarta, Hanoi',
      value: '+07:00',
    },
    {
      zone: '(UTC+08:00) Irkutsk Time, Ulaanbaatar, Kuala Lumpur, Hong Kong, China, Singapore, Taiwan, Perth',
      value: '+08:00',
    },
    {
      zone: '(UTC+08:45) Caiguna, Eucla, Madura, Mundrabilla',
      value: '+08:45',
    },
    {
      zone: '(UTC+09:00) North Korea, Yakutsk Time, Korea, Chita, Japan',
      value: '+09:00',
    },
    { zone: '(UTC+09:30) Darwin', value: '+09:30' },
    {
      zone: '(UTC+10:00) Vladivostok Time, Brisbane, Guam, Port Moresby',
      value: '+10:00',
    },
    { zone: '(UTC+10:30) Adelaide, Broken Hill', value: '+10:30' },
    {
      zone: '(UTC+11:00) Magadan Time, Melbourne, Tasmania, Lord Howe Island, Sakhalin Oblast (Severo-Kurilsky District), Solomon Islands, New Caledonia, Canberra, Sydney',
      value: '+11:00',
    },
    {
      zone: '(UTC+12:00) Norfolk Island, Kamchatka Krai, Chukotka Autonomous Okrug, Marshall Islands',
      value: '+12:00',
    },
    { zone: '(UTC+13:00) Fiji, New Zealand, Samoa, Tonga', value: '+13:00' },
    { zone: '(UTC+13:45) Chatham Islands', value: '+13:45' },
    { zone: '(UTC+14:00) Line Islands', value: '+14:00' },
  ];

  useEffect(() => {
    getSportsList();
    getLeaguesList();
    getTeamsList();
  }, []);

  return (
    <Modal
      isOpen={openModal}
      element={
        <ModalLayout
          ref={ModalLayoutRef}
          myOwnContainer
          setOpenModal={setOpenModal}
        >
          <Wrapper>
            <div className="title">
              <h2>Agregar evento</h2>
              <p>Agrega un nuevo evento a la plataforma</p>
            </div>
            <div className="body">
              <form onSubmit={handleCreateEvent}>
                <Dropdown>
                  <label htmlFor="sport">
                    Deporte al que pertenece el evento
                  </label>
                  <select name="sport" required onChange={handleChange}>
                    <option value="">Selecciona el deporte</option>
                    {sportsList.map((sport) => {
                      return (
                        <option value={sport.id} key={sport.id}>
                          {sport.name}
                        </option>
                      );
                    })}
                  </select>
                </Dropdown>
                <Dropdown>
                  <label htmlFor="league">
                    Liga a la que pertenece el evento
                  </label>
                  <select name="league" required onChange={handleChange}>
                    <option value="">Selecciona la liga</option>
                    {leaguesList
                      .filter((item) => {
                        return item?.sport?.id == newTeam?.sport; // Filtro para mostrar solo las ligas del deporte seleccionado
                      })
                      .map((league) => {
                        return (
                          <option value={league.id} key={league.id}>
                            {league.name}
                          </option>
                        );
                      })}
                  </select>
                </Dropdown>
                <div style={{ display: 'flex', gap: '10px' }}>
                  <Dropdown>
                    <label htmlFor="team1">Equipo 1</label>
                    <select name="team1" required onChange={handleChange}>
                      <option value="">Selecciona el equipo</option>
                      {teamsList
                        .filter((item) => {
                          return item.leagues
                            ?.map(({ id }) => {
                              return id;
                            })
                            .includes(+newTeam.league); // Filtro para mostrar solo las ligas del deporte seleccionado
                        })
                        .map((team) => {
                          return (
                            <option value={team.id} key={team.id}>
                              {team.name}
                            </option>
                          );
                        })}
                    </select>
                  </Dropdown>
                  <Dropdown>
                    <label htmlFor="team2">Equipo 2</label>
                    <select name="team2" required onChange={handleChange}>
                      <option value="">Selecciona el equipo</option>
                      {teamsList
                        .filter((item) => {
                          return item.leagues
                            ?.map(({ id }) => {
                              return id;
                            })
                            .includes(+newTeam.league); // Filtro para mostrar solo las ligas del deporte seleccionado
                        })
                        .map((team) => {
                          return (
                            <option value={team.id} key={team.id}>
                              {team.name}
                            </option>
                          );
                        })}
                    </select>
                  </Dropdown>
                </div>
                <Dropdown style={{ width: '100%' }}>
                  <label htmlFor="timezone">Zona Horaria</label>
                  <select
                    name="timezone"
                    required
                    value={newTeam.timezone}
                    onChange={handleChange}
                  >
                    <option value="">Selecciona el equipo</option>
                    {timezones.map((zone) => {
                      return (
                        <option value={zone.value} key={zone.value}>
                          {zone.zone}
                        </option>
                      );
                    })}
                  </select>
                </Dropdown>
                <FormField
                  type="datetime-local"
                  name="start_datetime"
                  labelText="Fecha de inicio del evento"
                  onChange={handleChange}
                />
                {/* <div className="exampleBox">
                  <div className="iconExample">
                    <img
                      src={
                        selectedFile !== ''
                          ? URL.createObjectURL(selectedFile)
                          : null
                      }
                      alt=""
                    />
                  </div>
                  <p>{newTeam.name}</p>
                </div> */}
                <div
                  className="message"
                  style={{
                    color:
                      message === 'Email de recuperación enviado'
                        ? 'green'
                        : 'red',
                  }}
                >
                  {message}
                </div>
                <Button
                  text="Agregar evento"
                  color="#fff"
                  backgroundColor="var(--primary)"
                />
              </form>
            </div>
          </Wrapper>
        </ModalLayout>
      }
    />
  );
};

export default AddEventModal;
