export const returnTime = (date, timezone, options) => {
  //const myTimeZone = localStorage.getItem('GMT');

  const d = new Date(date);

  return d.toLocaleString('es-MX', {
    ...options,
    //timeZone: myTimeZone,
    timeZone: timezone || localStorage.getItem('GMT'),
    hour12: false,
  });
};
