import React from 'react';
import styled from 'styled-components';

const FormFieldStyle = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 0;

  label {
    font-weight: 700;
  }

  input {
    height: 30px;
    border-radius: 10px;
    background-color: var(--secondaryBackground);
    box-shadow: 0px 4px 4px -4px var(--shadow);
    border: none;
    font-size: 13rem;
    padding: 0 10px;
    margin-top: 5px;
    max-width: 400px;
  }

  input:focus {
    outline: 2px solid var(--secondaryText);
  }
`;

const FormField = (props) => {
  return (
    <FormFieldStyle>
      <label htmlFor={props.name}>{props.labelText}</label>
      <input
        type={props.type || 'text'}
        name={props.name}
        value={props.value}
        placeholder={props.placeholder}
        autoComplete="off"
        onChange={props.onChange}
        required={props.required || true}
      />
    </FormFieldStyle>
  );
};

export default FormField;
