import styled from 'styled-components';
import Modal from '../../components/templates/modal';
import ModalLayout from '../../components/templates/modal/ModalLayout';
import Button from '../../components/atoms/button';
import { useState, useRef } from 'react';
import axiosInstance from '../../utils/axios';
import FormField from '../../components/atoms/FormField';
import { toast } from 'react-toastify';
import UserClients from '.';

const Wrapper = styled.div`
  text-align: center;
  width: 90%;
  max-width: 400px;
  background-color: var(--primaryBackground);
  border-radius: 5px;
  overflow: hidden;

  .title {
    background-color: #fff;
    text-align: left;
    padding: 20px;

    p {
      color: var(--secondaryText);
    }
  }

  .body {
    padding: 20px;
  }

  input {
    max-width: unset;
  }

  input[type='file'] {
    background-color: transparent;
    box-shadow: unset;
  }
  .exampleBox {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    color: var(--primaryText);
    font-weight: 700;

    .iconExample {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      overflow: hidden;

      img {
        width: 100%;
      }
    }
  }
  .message {
    margin-bottom: 15px;
  }
`;

const AcceptIdentity = ({ openModal, setOpenModal, user, refreshData }) => {
  const [message, setMessage] = useState('');
  const [event, setEvent] = useState({
    start_datetime: '',
  });
  const ModalLayoutRef = useRef();

  const handleAprobeIdentity = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axiosInstance.patch(
        `${process.env.REACT_APP_BASE_URL}identities/validate/${user.id}/`,
        { verfication_status: 'verified' }
      );
      toast.success('Identidad aprobada');
      setOpenModal(false);
    } catch (error) {
      console.log(error.response.data.detail.name);
    }
  };

  return (
    <Modal
      isOpen={openModal}
      element={
        <ModalLayout
          ref={ModalLayoutRef}
          myOwnContainer
          setOpenModal={setOpenModal}
        >
          <Wrapper>
            <div className="title">
              <h2>Aprobar identidad de {user.identity.owner.username}</h2>
            </div>
            <div className="body">
              <form>
                <Button
                  text="Aprobar identidad"
                  color="#fff"
                  backgroundColor="var(--primary)"
                  onClick={handleAprobeIdentity}
                />
              </form>
            </div>
          </Wrapper>
        </ModalLayout>
      }
    />
  );
};

export default AcceptIdentity;
