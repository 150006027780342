import styled from 'styled-components';
import Modal from '../../components/templates/modal';
import ModalLayout from '../../components/templates/modal/ModalLayout';
import Button from '../../components/atoms/button';
import { useState, useRef } from 'react';
import axiosInstance from '../../utils/axios';
import FormField from '../../components/atoms/FormField';
import { toast } from 'react-toastify';

const Wrapper = styled.div`
  text-align: center;
  width: 90%;
  max-width: 400px;
  background-color: var(--primaryBackground);
  border-radius: 5px;
  overflow: hidden;

  .title {
    background-color: #fff;
    text-align: left;
    padding: 20px;

    p {
      color: var(--secondaryText);
    }
  }

  form {
    margin-bottom: 20px;
  }

  .body {
    padding: 0 20px 20px 20px;
  }

  input {
    max-width: unset;
  }

  input[type='file'] {
    background-color: transparent;
    box-shadow: unset;
  }
  .exampleBox {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    color: var(--primaryText);
    font-weight: 700;

    .iconExample {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      overflow: hidden;

      img {
        width: 100%;
      }
    }
  }
  .message {
    margin-bottom: 15px;
  }
`;

const Dropdown = styled.div`
  padding-top: 20px;
  label {
    font-weight: 700;
  }

  select {
    height: 30px;
    width: 100%;
    border-radius: 10px;
    background-color: var(--secondaryBackground);
    box-shadow: 0px 4px 4px -4px var(--shadow);
    border: none;
    font-size: 13rem;
    padding: 0 10px;
    margin-top: 5px;
    max-width: 400px;
  }
`;

const RejectIdentity = ({
  openModal,
  setOpenModal,
  user,
  refreshData,
  errorList,
}) => {
  const [rejectData, setRejectData] = useState({
    error: '',
  });
  const ModalLayoutRef = useRef();
  console.log(rejectData);

  const handleRejectIdentity = async () => {
    try {
      const { data } = await axiosInstance.patch(
        `${process.env.REACT_APP_BASE_URL}identities/validate/${user.id}/`,
        rejectData
      );
      toast.success('Documento rechazado');
      setOpenModal(false);
    } catch (error) {
      console.log(error.response.data.detail.name);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setRejectData({
      ...rejectData,
      [name]: value,
    });
  };

  return (
    <Modal
      isOpen={openModal}
      element={
        <ModalLayout
          ref={ModalLayoutRef}
          myOwnContainer
          setOpenModal={setOpenModal}
        >
          <Wrapper>
            <div className="title">
              <h2>
                Rechazar documento de identidad de{' '}
                {user.identity.owner.username}
              </h2>
            </div>
            <div className="body">
              <form>
                <Dropdown style={{ width: '100%' }}>
                  <label htmlFor="timezone">Motivo del rechazo</label>
                  <select
                    name="error"
                    required
                    value={rejectData.error}
                    onChange={handleChange}
                  >
                    <option value="">Seleccione el motivo</option>
                    {errorList.detail.error.map((error, index) => {
                      return (
                        <option value={error[0]} key={index}>
                          {error[1]}
                        </option>
                      );
                    })}
                  </select>
                </Dropdown>
                {rejectData.error === 'other' && (
                  <FormField
                    type="text"
                    name="error_other_reason"
                    labelText="Describa la razon del rechazo"
                    onChange={handleChange}
                  />
                )}
              </form>
              <Button
                text="Rechazar identidad"
                color="#fff"
                backgroundColor="var(--primary)"
                onClick={handleRejectIdentity}
              />
            </div>
          </Wrapper>
        </ModalLayout>
      }
    />
  );
};

export default RejectIdentity;
