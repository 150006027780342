import { useState, useEffect } from 'react';
import styled from 'styled-components';
import Button from '../../../atoms/button';
import TeamListItem from './listItem';
import AddTeamModal from '../../../../pages/dashboard/AddTeamModal';
import axiosInstance from '../../../../utils/axios';
import FormField from '../../../atoms/FormField';

const Wrapper = styled.div`
  .textSecondary {
    color: var(--secondaryText);
  }

  & > div {
    margin-bottom: 40px;
  }

  div:last-child {
    margin-bottom: 0;
  }

  .rols_header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;

  thead {
    border-bottom: solid 3px #fadc41;
  }

  tr {
    position: relative;
  }

  tr:hover:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background-color: #fff;
    border-radius: 5px;
  }

  th,
  td {
    text-align: center;
    width: 20%;
    padding: 20px;
  }
`;

const TeamsAdmin = () => {
  const [openTeamModal, setOpenTeamModal] = useState(false);
  const [teamsList, setTeamsList] = useState([]);
  const [search, setSearch] = useState('');

  const getTeamsList = async () => {
    try {
      const { data } = await axiosInstance.get(
        `${process.env.REACT_APP_BASE_URL}teams/`
      );
      setTeamsList(data);
    } catch (error) {
      console.log(error.response.data.detail.name);
    }
  };

  useEffect(() => {
    getTeamsList();
  }, []);

  return (
    <Wrapper>
      <div className="rols_header">
        <div>
          <h2>Administrar equipos</h2>
          <p className="textSecondary">
            Crea y edita los equipos de la plataforma
          </p>
        </div>
        <div>
          <Button
            text="Crear equipo"
            color="#fff"
            backgroundColor="#324250"
            onClick={(e) => {
              e.preventDefault();
              setOpenTeamModal(true);
            }}
          />
        </div>
      </div>
      <FormField
        type="search"
        name="name"
        labelText="Buscar equipo"
        value={search}
        onChange={(e) => {
          setSearch(e.target.value);
        }}
      />
      <Table>
        <thead>
          <tr>
            <th>Nombre del equipo</th>
            <th>Ligas a las que pertenece</th>
            <th>Deporte al que pertenece</th>
            <th>Icono</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {teamsList
            .filter((item) => {
              return item.name.toLowerCase().includes(search);
            })
            .map((team) => {
              return (
                <TeamListItem
                  team={team}
                  key={team.id}
                  refreshData={getTeamsList}
                />
              );
            })}
        </tbody>
      </Table>
      {openTeamModal && (
        <AddTeamModal
          openModal={openTeamModal}
          setOpenModal={setOpenTeamModal}
        />
      )}
    </Wrapper>
  );
};

export default TeamsAdmin;
