import React from 'react';
import Main from '../../layouts/main';
import AccountMenu from '../../components/templates/account';
import SportsAdminComponent from '../../components/templates/account/sports/index';

const SportsAdmin = () => {
  return (
    <Main>
      <AccountMenu>
        <SportsAdminComponent />
      </AccountMenu>
    </Main>
  );
};

export default SportsAdmin;
