import React from 'react';
import Main from '../../layouts/main';
import AccountMenu from '../../components/templates/account';
import RolsComponent from '../../components/templates/account/Rols';

const Rols = () => {
  return (
    <Main>
      <AccountMenu>
        <RolsComponent />
      </AccountMenu>
    </Main>
  );
};

export default Rols;
