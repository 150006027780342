import React from 'react';
import Main from '../../layouts/main';
import AccountMenu from '../../components/templates/account';
import TeamsAdminComponent from '../../components/templates/account/teamsAdmin/index';

const TeamsAdmin = () => {
  return (
    <Main>
      <AccountMenu>
        <TeamsAdminComponent />
      </AccountMenu>
    </Main>
  );
};

export default TeamsAdmin;