import { useState, useEffect } from 'react';
import axiosInstance from '../../utils/axios';
import Main from '../../layouts/main';
import styled from 'styled-components';
import Button from '../../components/atoms/button';
import ActionButton from '../../components/atoms/actionButtons';
import SideComponent from '../dashboard/sideComponent';
import WrapperUser from './WrapperUser';

const Wrapper = styled.div`
  background-color: var(--primaryBackground);
  display: flex;
  height: 100%;
  .main {
    width: 100%;
    height: calc(100vh - 140px);
    overflow-y: scroll;

    .addButtonsBox {
      padding: 20px;
      display: flex;
      justify-content: space-around;
    }
  }

  .info {
    background-color: var(--secondaryBackground);
    width: 500px;
    height: calc(100vh - 140px);
    overflow-y: auto;
  }
`;

const UserClients = () => {
  const [filter, setFilter] = useState('waiting_for_admin');
  const [errorList, setErrorList] = useState({
    error: [],
    status: [],
    verification_status: [],
  });
  const [userList, setUserList] = useState({
    data: [],
    detail: {},
  });

  const getUsersList = async () => {
    try {
      const { data } = await axiosInstance.get(
        `${process.env.REACT_APP_BASE_URL}identities/validate/?status=${filter}`
      );
      setUserList(data);
    } catch (error) {
      console.log(error.response.data.detail.name);
    }
  };

  const verificationChoicesList = async () => {
    try {
      const { data } = await axiosInstance.get(
        `${process.env.REACT_APP_BASE_URL}identities/validate/choices/`
      );
      setErrorList(data);
    } catch (error) {
      console.log(error.response.data.detail.name);
    }
  };

  useEffect(() => {
    getUsersList();
    verificationChoicesList();
  }, [filter]);

  return (
    <Main>
      <Wrapper>
        <div className="main">
          <div
            style={{
              margin: '20px',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Button
              text="Por aprobar"
              color="#fff"
              backgroundColor="var(--primary)"
              onClick={() => setFilter('waiting_for_admin')}
            />
            <Button
              text="Pendientes de modificacion"
              color="#fff"
              backgroundColor="var(--primary)"
              onClick={() => setFilter('waiting_for_user')}
            />
            <Button
              text="Aprobados"
              color="#fff"
              backgroundColor="var(--primary)"
              onClick={() => setFilter('evaluated')}
            />
          </div>
          {userList.data.map((user) => {
            return (
              <WrapperUser
                key={user.id}
                user={user}
                refreshData={getUsersList}
                status={filter}
                errorList={errorList}
              />
            );
          })}
        </div>
        <div className="info">
          <SideComponent />
        </div>
      </Wrapper>
    </Main>
  );
};

export default UserClients;
