import { useState, useEffect } from 'react';
import axiosInstance from '../../../../utils/axios';
import styled from 'styled-components';
import Button from '../../../atoms/button';
import ListItem from './listItem';
import AddLeagueModal from '../../../../pages/dashboard/AddLeagueModal';
import FormField from '../../../atoms/FormField';

const Wrapper = styled.div`
  .textSecondary {
    color: var(--secondaryText);
  }

  & > div {
    margin-bottom: 40px;
  }

  div:last-child {
    margin-bottom: 0;
  }

  .rols_header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;

  thead {
    border-bottom: solid 3px #fadc41;
  }

  tr {
    position: relative;
  }

  tr:hover:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background-color: #fff;
    border-radius: 5px;
  }

  td {
    text-align: center;
    padding: 20px;
  }

  th,
  & > td {
    width: 25%;
    padding: 20px;
  }
`;

const LeaguesAdmin = () => {
  const [openLeagueModal, setOpenLeagueModal] = useState(false);
  const [leaguesList, setLeaguesList] = useState([]);
  const [search, setSearch] = useState('');

  const getLeaguesList = async () => {
    try {
      const { data } = await axiosInstance.get(
        `${process.env.REACT_APP_BASE_URL}leagues/`
      );
      setLeaguesList(data);
    } catch (error) {
      console.log(error.response.data.detail.name);
    }
  };

  useEffect(() => {
    getLeaguesList();
  }, []);

  return (
    <Wrapper>
      <div className="rols_header">
        <div>
          <h2>Administrar ligas</h2>
          <p className="textSecondary">
            Crea y edita las ligas de la plataforma
          </p>
        </div>
        <div>
          <Button
            text="Crear liga"
            color="#fff"
            backgroundColor="#324250"
            onClick={(e) => {
              e.preventDefault();
              setOpenLeagueModal(true);
            }}
          />
        </div>
      </div>
      <FormField
        type="search"
        name="name"
        labelText="Buscar liga"
        value={search}
        onChange={(e) => {
          setSearch(e.target.value);
        }}
      />
      <Table>
        <thead>
          <tr>
            <th>Nombre de la liga</th>
            <th>Deporte</th>
            <th>Icono</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {leaguesList
            .filter((item) => {
              return item.name.toLowerCase().includes(search);
            })
            .map((league) => {
              return (
                <ListItem
                  league={league}
                  key={league.id}
                  refreshData={getLeaguesList}
                />
              );
            })}
        </tbody>
      </Table>
      {openLeagueModal && (
        <AddLeagueModal
          openModal={openLeagueModal}
          setOpenModal={setOpenLeagueModal}
        />
      )}
    </Wrapper>
  );
};

export default LeaguesAdmin;
