import styled from 'styled-components';
import Modal from '../../../components/templates/modal/';
import ModalLayout from '../../../components/templates/modal/ModalLayout';
import Button from '../../../components/atoms/button';
import { useState, useRef } from 'react';
import axiosInstance from '../../../utils/axios';
import FormField from '../../../components/atoms/FormField';
import { toast } from 'react-toastify';

const Wrapper = styled.div`
  text-align: center;
  width: 90%;
  max-width: 400px;
  background-color: var(--primaryBackground);
  border-radius: 5px;
  overflow: hidden;

  .title {
    background-color: #fff;
    text-align: left;
    padding: 20px;

    p {
      color: var(--secondaryText);
    }
  }

  .body {
    padding: 0 20px 20px 20px;
  }

  input {
    max-width: unset;
  }

  input[type='file'] {
    background-color: transparent;
    box-shadow: unset;
  }
  .exampleBox {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    color: var(--primaryText);
    font-weight: 700;

    .iconExample {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      overflow: hidden;

      img {
        width: 100%;
      }
    }
  }
  .message {
    margin-bottom: 15px;
  }
`;

const Dropdown = styled.div`
  label {
    font-weight: 700;
  }

  select {
    height: 30px;
    width: 100%;
    border-radius: 10px;
    background-color: var(--secondaryBackground);
    box-shadow: 0px 4px 4px -4px var(--shadow);
    border: none;
    font-size: 13rem;
    padding: 0 10px;
    margin-top: 5px;
    max-width: 400px;
  }
`;

const EditEventModal = ({
  openModal,
  setOpenModal,
  eventData,
  refreshData,
}) => {
  const [message, setMessage] = useState('');
  const [event, setEvent] = useState({
    start_datetime: '',
  });
  const ModalLayoutRef = useRef();

  const handleEditEvent = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axiosInstance.patch(
        `${process.env.REACT_APP_BASE_URL}events/${eventData.id}/`,
        {
          start_datetime: `${event.start_datetime}:00-06:00`,
        }
      );
      toast.success('Hora de evento actualizada');
      setOpenModal(false);
      refreshData();
    } catch (error) {
      setMessage(error.response.data.detail.error[0]);
      toast.success('Ocurrio algo, no se pudo actualizar el horario');
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEvent({
      ...event,
      [name]: value,
    });
  };

  return (
    <Modal
      isOpen={openModal}
      element={
        <ModalLayout
          ref={ModalLayoutRef}
          myOwnContainer
          setOpenModal={setOpenModal}
        >
          <Wrapper>
            <div className="title">
              <h2>Editar horario de evento</h2>
              <p>Ingresar horario CDMX</p>
            </div>
            <div className="body">
              <form>
                <FormField
                  type="datetime-local"
                  name="start_datetime"
                  labelText="Fecha de inicio del evento"
                  value={event.start_datetime}
                  placeholder="Escribe el nombre del deporte"
                  onChange={handleChange}
                />
                <Button
                  text="Editar horario"
                  color="#fff"
                  backgroundColor="var(--primary)"
                  onClick={handleEditEvent}
                />
              </form>
            </div>
          </Wrapper>
        </ModalLayout>
      }
    />
  );
};

export default EditEventModal;
