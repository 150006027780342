import React from 'react';
import styled from 'styled-components';
import ProfileImage from '../../../assets/images/profile.jpg';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 150px;

  .textSecondary {
    color: var(--secondaryText);
  }

  .user_data {
    display: flex;
    align-items: center;

    .user_name {
      font-weight: 700;
    }

    & > div {
      margin: 20px;
    }

    .user_image {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      overflow: hidden;

      img {
        width: 100%;
      }
    }
  }

  .user_actions {
    button {
      margin: 20px;
      font-weight: 700;
    }

    .challenge_user {
      color: var(--accent);
    }

    .block_user {
      color: var(--warning);
    }
  }
`;

const FriendsListItem = () => {
  return (
    <Wrapper>
      <div className="user_data">
        <div className="user_image">
          <img src={ProfileImage} alt="" />
        </div>
        <div>
          <p className="user_name">Julio Mora</p>
          <p className="textSecondary">#butowski930</p>
        </div>
      </div>
      <div className="user_actions">
        <button className="challenge_user">Retar</button>
        <button className="block_user">Bloquear</button>
      </div>
    </Wrapper>
  );
};

export default FriendsListItem;
