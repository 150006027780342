import axiosInstance from '../../../../utils/axios';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import Modal from '../../modal';
import ModalLayout from '../../modal/ModalLayout';
import Button from '../../../atoms/button';
import { useState, useRef } from 'react';

const Wrapper = styled.div`
  text-align: center;
  width: 90%;
  max-width: 400px;
  background-color: var(--primaryBackground);
  border-radius: 5px;
  overflow: hidden;

  .title {
    background-color: #fff;
    text-align: left;
    padding: 20px;

    p {
      color: var(--secondaryText);
    }
  }

  .body {
    padding: 40px;
  }

  input {
    max-width: unset;
  }

  input[type='file'] {
    background-color: transparent;
    box-shadow: unset;
  }
  .exampleBox {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    color: var(--primaryText);
    font-weight: 700;

    .iconExample {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      overflow: hidden;

      img {
        width: 100%;
      }
    }
  }
  .message {
    margin-bottom: 15px;
  }
`;

const Dropdown = styled.div`
  label {
    font-weight: 700;
  }

  select {
    height: 30px;
    width: 100%;
    border-radius: 10px;
    background-color: var(--secondaryBackground);
    box-shadow: 0px 4px 4px -4px var(--shadow);
    border: none;
    font-size: 13rem;
    padding: 0 10px;
    margin-top: 5px;
    max-width: 400px;
  }
`;

const DeleteSportModal = ({
  openModal,
  setOpenModal,
  sportData,
  refreshData,
}) => {
  const userData = localStorage.getItem('user');
  const token = JSON.parse(userData);
  const [message, setMessage] = useState('');
  const ModalLayoutRef = useRef();

  const HandleDeleteSport = async (e) => {
    e.preventDefault();
    try {
      let authresult = await axiosInstance.get(
        `${process.env.REACT_APP_BASE_URL}sports/${sportData.id}/disable/`
      );
      toast.success('Deporte inhabilitado');
      setOpenModal(false);
      refreshData();
    } catch (err) {
      setMessage(err.response.data.message);
    }
  };

  const enableSport = async (e) => {
    e.preventDefault();
    try {
      let authresult = await axiosInstance.get(
        `${process.env.REACT_APP_BASE_URL}sports/${sportData.id}/enable/`
      );
      toast.success('Deporte habilitado');
      setOpenModal(false);
      refreshData();
    } catch (err) {
      setMessage(err.response.data.message);
    }
  };

  return (
    <Modal
      isOpen={openModal}
      element={
        <ModalLayout
          ref={ModalLayoutRef}
          myOwnContainer
          setOpenModal={setOpenModal}
        >
          <Wrapper>
            <div className="title">
              <h2>
                Estas a punto de{' '}
                {sportData.status ? 'inhabilitar' : 'habilitar'}{' '}
                {sportData.name}, deseas continuar?
              </h2>
              {sportData.status && (
                <p>
                  Ten en cuenta que se inhabilitaran tambien todos los equipos y
                  ligas relacionadas al deporte
                </p>
              )}
            </div>
            <div className="body">
              <Button
                text={sportData.status ? 'Inhabilitar' : 'Habilitar'}
                color="#fff"
                backgroundColor="var(--primary)"
                onClick={sportData.status ? HandleDeleteSport : enableSport}
              />
            </div>
          </Wrapper>
        </ModalLayout>
      }
    />
  );
};

export default DeleteSportModal;
