import React from 'react';
import Main from '../../layouts/main';
import AccountMenu from '../../components/templates/account';
import LeaguesAdminComponent from '../../components/templates/account/leaguesAdmin/index';

const LeaguesAdmin = () => {
  return (
    <Main>
      <AccountMenu>
        <LeaguesAdminComponent />
      </AccountMenu>
    </Main>
  );
};

export default LeaguesAdmin;