import React from 'react';
import styled from 'styled-components';

const ButtonComponent = styled.button`
  width: 30px;
  height: 30px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    filter: opacity(50%);
  }
`;

const ActionButton = (props) => {
  return (
    <ButtonComponent
      style={{ backgroundColor: props.backgroundColor, color: props.color }}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.text}
    </ButtonComponent>
  );
};

export default ActionButton;
