import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import axiosInstance from '../../../../utils/axios';
import FormField from '../../../atoms/FormField';
import Modal from '../../modal';
import ModalLayout from '../../modal/ModalLayout';
import Button from '../../../atoms/button';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import { ListItem } from '@mui/material';

const icon = <CheckBoxOutlineBlankIcon fontSize="large" />;
const checkedIcon = <CheckBoxIcon fontSize="large" />;

const CustomAutocomplete = styled(Autocomplete)`
  margin-top: 5px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 4px 4px -4px var(--shadow);
  color: red;

  fieldset {
    border-color: transparent;
  }

  *,
  li {
    font-size: 16rem !important;
  }
`;

const CustomCheckbox = styled(Checkbox)`
  width: 30px;
  height: 30px;
  * {
    font-size: 24rem !important;
  }
`;

const Wrapper = styled.div`
  text-align: center;
  width: 90%;
  max-width: 400px;
  background-color: var(--primaryBackground);
  border-radius: 5px;
  overflow: hidden;

  h3 {
    color: var(--primary);
    font-size: 15rem;
  }

  .title {
    background-color: #fff;
    border-bottom: solid var(--accent) 5px;
    text-align: left;
    padding: 20px;

    p {
      color: var(--secondaryText);
    }
  }

  .body {
    padding: 20px 40px 40px 40px;
  }

  input {
    max-width: unset;
  }

  input[type='file'] {
    background-color: transparent;
    box-shadow: unset;
  }
  .exampleBox {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    color: var(--primaryText);
    font-weight: 700;

    .iconExample {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      overflow: hidden;

      img {
        width: 100%;
      }
    }
  }
  .message {
    margin-bottom: 15px;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 2fr repeat(4, 1fr);
  grid-gap: 10px;
  justify-items: center;
  margin-bottom: 20px;

  .align_left {
    justify-self: start;
  }
`;

const EditRolModal = ({ openModal, setOpenModal, refreshData, rolData }) => {
  const [test, setTest] = useState({
    accounts: rolData.permissions.Usuarios,
    sports: rolData.permissions.Deportes,
    leagues: rolData.permissions.Ligas,
    teams: rolData.permissions.Equipos,
    events: rolData.permissions.Eventos,
    challenges: rolData.permissions.Retos,
    identities_validation: rolData.permissions.Validacion,
    client_management: rolData.permissions.Administración,
  });
  const [currentPermissions, setCurrentPermissions] = useState(
    rolData.permissions
  );
  const [newRol, setNewRol] = useState({
    name: rolData.name,
    description: rolData.description,
    permission_list: test,
  });
  const [appsList, setAppsList] = useState([
    ['accounts', 'Usuarios'],
    ['sports', 'Deportes'],
    ['leagues', 'Ligas'],
    ['teams', 'Equipos'],
    ['events', 'Eventos'],
    ['challenges', 'Retos'],
    ['identities_validation', 'Validacion'],
    ['client_management', 'Administración'],
  ]);
  const ModalLayoutRef = useRef();

  console.log('data', test);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewRol({
      ...newRol,
      [name]: value,
    });
  };

  const handleChecks = (e) => {
    const { name, value, checked } = e.target;

    // Case 1 : The user checks the box
    if (checked) {
      setTest({
        ...test,
        [name]: test[name] ? [...test[name], value] : [value],
      });
    }

    // Case 2  : The user unchecks the box
    else {
      setTest({
        ...test,
        [name]: test[name].filter((e) => e !== value),
      });
    }
  };

  const getAppsList = async () => {
    try {
      const { data } = await axiosInstance.get(
        `${process.env.REACT_APP_BASE_URL}auth/user_roles/apps/`
      );

      setAppsList(data.apps);
    } catch (error) {
      console.log(error.response.data.detail.name);
    }
  };

  const handleEditRol = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axiosInstance.patch(
        `${process.env.REACT_APP_BASE_URL}auth/user_roles/${rolData.id}/edit/`,
        {
          name: newRol.name,
          description: newRol.description,
          permission_list: test,
        }
      );
      toast.success('Evento creado');
      setOpenModal(false);
      refreshData();
    } catch (error) {
      // setMessage(error.response.data.detail.error[0]);
    }
  };

  /* useEffect(() => {
    getAppsList();
  }, []); */

  return (
    <Modal
      isOpen={openModal}
      element={
        <ModalLayout
          ref={ModalLayoutRef}
          myOwnContainer
          setOpenModal={setOpenModal}
        >
          <Wrapper>
            <div className="title">
              <h2>Editar rol</h2>
              <p>Actualiza los permisos para este rol</p>
            </div>
            <div className="body">
              <form /* onSubmit={handleCreateEvent} */ onChange={handleChange}>
                <FormField
                  type="text"
                  name="name"
                  value={newRol.name}
                  placeholder="Escribe el nombre del rol"
                  labelText="Nombre"
                  onChange={handleChange}
                />
                <FormField
                  type="text"
                  name="description"
                  value={newRol.description}
                  placeholder="Escribe una breve descripcion"
                  labelText="Descripción"
                  onChange={handleChange}
                />
              </form>
              <form onChange={handleChecks}>
                <h3>Permisos</h3>
                <Grid>
                  <div></div>
                  <div>Crear</div>
                  <div>Ver</div>
                  <div>Editar</div>
                  <div>Eliminar</div>
                  {appsList?.map((app) => {
                    return (
                      <>
                        <div className="align_left">
                          <label htmlFor={app[0]}>{app[1]}</label>
                        </div>
                        <div>
                          <input
                            type="checkbox"
                            name={app[0]}
                            value="Crear"
                            defaultChecked={currentPermissions[
                              app[1]
                            ]?.includes('Crear')}
                          />
                        </div>
                        <div>
                          <input
                            type="checkbox"
                            name={app[0]}
                            value="Ver"
                            defaultChecked={currentPermissions[
                              app[1]
                            ]?.includes('Ver')}
                          />
                        </div>
                        <div>
                          <input
                            type="checkbox"
                            name={app[0]}
                            value="Modificar"
                            defaultChecked={currentPermissions[
                              app[1]
                            ]?.includes('Modificar')}
                          />
                        </div>
                        <div>
                          <input
                            type="checkbox"
                            name={app[0]}
                            value="Eliminar"
                            defaultChecked={currentPermissions[
                              app[1]
                            ]?.includes('Eliminar')}
                          />
                        </div>
                      </>
                    );
                  })}
                </Grid>
                <Button
                  text="Actualizar rol"
                  color="#fff"
                  backgroundColor="var(--primary)"
                  onClick={handleEditRol}
                />
              </form>
            </div>
          </Wrapper>
        </ModalLayout>
      }
    />
  );
};

export default EditRolModal;
