import { useRef, useState, useEffect } from 'react';
import axiosInstance from '../../utils/axios';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import GoChallengeLogo from '../../assets/images/go_challenge_logo.png';
import ProfileImage from '../../assets/images/profile.jpg';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { styled as muistyled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';
// import { useAuth } from '../../hooks/useAuth';

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  background-color: #fff;
`;

const WrapperLogo = styled.div`
  width: 150px;
  margin: 0 20px;

  img {
    width: 100%;
  }
`;

const WrapperMenu = styled.div`
  ul {
    display: flex;
    gap: 20px;
  }

  li:last-child {
    color: var(--warning);
  }

  li {
    display: flex;
    align-items: center;
    gap: 5px;

    a {
      color: var(--primaryText);
    }
  }

  .roundNotification {
    display: block;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    background-color: var(--accent);
    text-align: center;
    line-height: 25px;
    color: #fff;
  }
`;

const WrapperProfile = styled.div`
  display: flex;
  margin-right: 20px;
  gap: 20px;

  .profile_notificaciones {
    display: flex;
    align-items: center;
  }

  .roundNotification {
    display: block;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    background-color: var(--accent);
    text-align: center;
    line-height: 25px;
    color: #fff;
  }

  .profile_info {
    text-align: right;
    position: relative;

    .hide {
      display: none;
    }

    .dropdown_menu {
      position: absolute;
      background-color: #fff;
      box-shadow: 4px 4px 4px -4px var(--primaryText);
      border-radius: 5px;
      right: 0;
      top: 70px;
      padding: 10px;
      border: solid 1px var(--primary);
      z-index: 1;

      li {
        padding: 5px;

        a {
          color: var(--primary);
          font-weight: 500;
        }
      }

      li:hover {
        background-color: var(--primaryBackground);
        border-radius: 5px;
      }

      li:last-child button {
        color: var(--warning);
        font-weight: 700;
      }
    }

    p {
      font-weight: 700;

      span {
        color: var(--accent);
        font-weight: 300;
      }
    }

    div {
      display: flex;
      gap: 10px;
      align-items: center;
    }
  }

  .profile_picture {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`;

const FastCheckBox = styled.div`
  display: flex;
  justify-content: center;
  color: 'var(--primary)';
`;

const GCRegistrySwitch = muistyled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  '& .css-jsexje-MuiSwitch-thumb': {
    backgroundColor: '#e37222',
  },
  '& .Mui-checked': {
    color: '#e37222',
  },
  '& .Mui-checked+.MuiSwitch-track': {
    backgroundColor: '#e37222',
  },
}));

const Navbar = () => {
  const [checked, setChecked] = useState(false); //Select if the registry method is quick or not
  const [errorMessage, setErrorMessage] = useState(''); // Show a message if the pass not complete the format
  const dropdownMenuRef = useRef(null);
  const [userData, setUserData] = useState('');

  // const auth = useAuth();

  const navigate = useNavigate();

  const toggleMenu = () => {
    dropdownMenuRef.current.classList.toggle('hide');
  };

  const handleCheck = (event) => {
    setChecked(event.target.checked);
    setErrorMessage('');
  };

  const logout = () => {
    // auth.signOut();
    localStorage.removeItem('user');
    navigate('/login');
  };

  const getUserData = async () => {
    try {
      const { data } = await axiosInstance.get(
        `${process.env.REACT_APP_BASE_URL}auth/users/current/`
      );
      setUserData(data);
    } catch (error) {
      // toast.success('Hubo un error aceptando el reto');
      console.log(error.response.data.detail.name);
    }
  };

  useEffect(() => {
    getUserData();
  }, []);

  return (
    <Wrapper>
      <WrapperLogo>
        <img src={GoChallengeLogo} alt="" />
      </WrapperLogo>
      <WrapperMenu>
        <ul>
          <li>
            <a href="/">Dashboard</a>
          </li>
          <li>
            <a href="/manage-clients">Clientes</a>
          </li>
          <li>
            <a href="/clients">Validar clientes</a>
          </li>
          <li>
            <a href="/manage-users">Administradores</a>
          </li>
        </ul>
      </WrapperMenu>
      <div style={{ flexGrow: '1' }}></div>
      <WrapperProfile>
        {/* <div className="profile_notificaciones">
          <NotificationsActiveIcon
            style={{ width: '30px', height: '30px', color: '#394A58' }}
          />
          <span className="roundNotification">4</span>
        </div> */}
        <div className="profile_info">
          <div>
            <p>
              Hola, {userData.first_name || userData.username} <br />
            </p>
            <div className="profile_picture" onClick={toggleMenu}>
              <img
                src={userData.avatar ? userData.avatar : ProfileImage}
                alt=""
              />
            </div>
          </div>
          <div className="dropdown_menu hide" ref={dropdownMenuRef}>
            <ul>
              {/* <li>
                <FastCheckBox>
                  <p>
                    <LightModeIcon
                      style={{
                        width: '20px',
                        height: '20px',
                        color: 'var(--primary)',
                      }}
                    />
                  </p>
                  <GCRegistrySwitch
                    checked={checked}
                    onChange={handleCheck}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                  <p>
                    <DarkModeIcon
                      style={{
                        width: '20px',
                        height: '20px',
                        color: 'var(--primary)',
                      }}
                    />
                  </p>
                </FastCheckBox>
              </li>
              <hr /> */}
              <li>
                <a href="/account/profile">Perfil</a>
              </li>
              <li>
                <a href="/account/roles">Roles</a>
              </li>
              <li>
                <a href="/account/sports-admin">Administrar deportes</a>
              </li>
              <li>
                <a href="/account/leagues-admin">Administrar ligas</a>
              </li>
              <li>
                <a href="/account/teams-admin">Administrar equipos</a>
              </li>
              <li>
                <a href="/account/gcc-manage">GCC</a>
              </li>
              <hr />
              <li className="logout">
                <button onClick={logout}>Cerrar sesión</button>
              </li>
            </ul>
          </div>
        </div>
      </WrapperProfile>
    </Wrapper>
  );
};

export default Navbar;
