import Modal from '../../../../components/templates/modal';
import axiosInstance from '../../../../utils/axios';
import styled from 'styled-components';
import ModalLayout from '../../../../components/templates/modal/ModalLayout';
import FormField from '../../../../components/atoms/FormField';
import Button from '../../../../components/atoms/button';
import { useState, useRef, useEffect } from 'react';

import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { toast } from 'react-toastify';

const icon = <CheckBoxOutlineBlankIcon fontSize="large" />;
const checkedIcon = <CheckBoxIcon fontSize="large" />;

const CustomAutocomplete = styled(Autocomplete)`
  margin-top: 5px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 4px 4px -4px var(--shadow);
  color: red;

  fieldset {
    border-color: transparent;
  }

  *,
  li {
    font-size: 16rem !important;
  }
`;

const CustomCheckbox = styled(Checkbox)`
  width: 30px;
  height: 30px;
  * {
    font-size: 24rem !important;
  }
`;

const Wrapper = styled.div`
  text-align: center;
  width: 90%;
  max-width: 400px;
  background-color: var(--primaryBackground);
  border-radius: 5px;
  overflow: hidden;

  .title {
    background-color: #fff;
    border-bottom: solid var(--accent) 5px;
    text-align: left;
    padding: 20px;

    p {
      color: var(--secondaryText);
    }
  }

  .body {
    padding: 40px;
  }

  input {
    max-width: unset;
  }

  input[type='file'] {
    background-color: transparent;
    box-shadow: unset;
  }
  .exampleBox {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    color: var(--primaryText);
    font-weight: 700;

    .iconExample {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      overflow: hidden;

      img {
        width: 100%;
      }
    }
  }
  .message {
    margin-bottom: 15px;
  }
`;

const Dropdown = styled.div`
  padding-top: 20px;

  label {
    font-weight: 700;
  }

  select {
    height: 30px;
    width: 100%;
    border-radius: 10px;
    background-color: var(--secondaryBackground);
    box-shadow: 0px 4px 4px -4px var(--shadow);
    border: none;
    font-size: 13rem;
    padding: 0 10px;
    margin-top: 5px;
    max-width: 400px;
  }
`;

const EditSportModal = ({ openModal, setOpenModal, refreshData, teamData }) => {
  const [errorMessage, setErrorMessage] = useState();
  const [selectedFile, setSelectedFile] = useState('');
  const [sportsList, setSportsList] = useState([]);
  const [leaguesList, setLeaguesList] = useState([]);
  const multipleValuesRef = useRef();
  const [newTeam, setNewTeam] = useState({
    name: teamData.name,
    image: teamData.image,
    sport: teamData.sport.id,
    leagues: teamData.leagues.map(({ id }) => id),
  });

  const [message, setMessage] = useState('');
  const ModalLayoutRef = useRef();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewTeam({
      ...newTeam,
      [name]: value,
    });
  };

  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const HandleEditTeam = async (e) => {
    e.preventDefault();

    const dataForm = new FormData();
    if (teamData.name !== newTeam.name) {
      dataForm.append('name', newTeam.name);
    }
    if (teamData.leagues !== newTeam.leagues) {
      dataForm.append('leagues', newTeam.leagues);
    }
    if (selectedFile) {
      dataForm.append('image', selectedFile);
    }

    try {
      const { data } = await axiosInstance.patch(
        `${process.env.REACT_APP_BASE_URL}teams/${teamData.id}/`,
        dataForm
      );
      toast.success('Equipo editado');
      setOpenModal(false);
      refreshData();
    } catch (error) {
      setErrorMessage(error.response.data.detail.name);
    }
  };

  const getSportsList = async () => {
    try {
      const { data } = await axiosInstance.get(
        `${process.env.REACT_APP_BASE_URL}sports/?status=1/`
      );
      setSportsList(data);
    } catch (error) {
      console.log(error.response.status);
    }
  };

  const getLeaguesList = async () => {
    try {
      const { data } = await axiosInstance.get(
        `${process.env.REACT_APP_BASE_URL}leagues/?status=1/`
      );
      setLeaguesList(data);
    } catch (error) {
      console.log(error.response.data.detail.name);
    }
  };

  useEffect(() => {
    getSportsList();
    getLeaguesList();
  }, []);

  return (
    <Modal
      isOpen={openModal}
      element={
        <ModalLayout
          ref={ModalLayoutRef}
          myOwnContainer
          setOpenModal={setOpenModal}
        >
          <Wrapper>
            <div className="title">
              <h2>Editar equipo</h2>
            </div>
            <div className="body">
              <form>
                {/* <Dropdown>
                  <label htmlFor="sport">
                    Deporte al que pertenece el equipo
                  </label>
                  <select
                    name="sport"
                    required
                    onChange={handleChange}
                    value={newTeam.sport}
                  >
                    <option value="">Selecciona el deporte</option>
                    {sportsList.map((sport) => {
                      return (
                        <option value={sport.id} key={sport.id}>
                          {sport.name}
                        </option>
                      );
                    })}
                  </select>
                </Dropdown> */}
                {/* <Dropdown>
                  <label htmlFor="leagues2">Opcion para ligas</label>
                  <select
                    ref={multipleValuesRef}
                    style={{ height: '100px' }}
                    name="leagues2"
                    required
                    value={newTeam.leagues}
                    onChange={(event, newValue) => {
                      setNewTeam({
                        ...newTeam,
                        leagues: [...multipleValuesRef.current.options]
                          .filter((option) => option.selected)
                          .map((option) => option.value),
                      });
                    }}
                    multiple
                  >
                    {leaguesList.map((leagues) => {
                      return (
                        <option value={leagues.id} key={leagues.id}>
                          {leagues.name}
                        </option>
                      );
                    })}
                  </select>
                </Dropdown> */}
                {/* <Dropdown>
                  <label htmlFor="league">
                    Ligas a la que pertenece el equipo
                    <br />
                  </label>
                </Dropdown>
                <CustomAutocomplete
                  multiple
                  onChange={(event, newValue) => {
                    setNewTeam({
                      ...newTeam,
                      leagues: Array.from(newValue).map(({ id }) => id),
                    });
                  }}
                  options={leaguesList}
                  defaultValue={newTeam.leagues}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option.name}
                  renderOption={(props, option, { selected }) => (
                    <li
                      {...props}
                      style={{
                        fontSize: '16rem',
                      }}
                    >
                      <CustomCheckbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.name}
                    </li>
                  )}
                  style={{ width: '100%' }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Selecciona las ligas"
                      required={newTeam.leagues.length === 0}
                    />
                  )}
                /> */}
                <FormField
                  type="text"
                  name="name"
                  value={newTeam.name}
                  placeholder="Escribe el nombre del equipo"
                  labelText="Nombre del equipo"
                  onChange={handleChange}
                />
                <FormField
                  type="file"
                  name="image"
                  labelText="Logo del equipo"
                  onChange={handleFileSelect}
                />
                <div className="exampleBox">
                  <div className="iconExample">
                    <img
                      src={
                        selectedFile === ''
                          ? teamData.image
                          : URL.createObjectURL(selectedFile)
                      }
                      alt=""
                    />
                  </div>
                  <p>{newTeam.name}</p>
                </div>
                <div
                  className="message"
                  style={{
                    color:
                      message === 'Email de recuperación enviado'
                        ? 'green'
                        : 'red',
                  }}
                >
                  {message}
                </div>
                <Button
                  text="Editar equipo"
                  color="#fff"
                  backgroundColor="var(--primary)"
                  onClick={HandleEditTeam}
                />
              </form>
            </div>
          </Wrapper>
        </ModalLayout>
      }
    />
  );
};

export default EditSportModal;
