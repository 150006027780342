import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/atoms/button';
import styled from 'styled-components';
import GoChallengeLogo from '../../assets/images/go_challenge_logo.png';
// import FootballPlayer from '../../assets/images/football-player2.png';
import RecoverPassModal from '../../components/templates/account/RecoverPassModal';
import axios from 'axios';
import axiosInstance from '../../utils/axios';

const WrapperLeft = styled.div`
  position: relative;
  padding: 20px;
  height: 100vh;
  overflow: hidden;
  display: grid;
  grid-template-rows: 100px 1fr auto;
`;

const WrapperLogo = styled.div`
  width: 200px;

  img {
    width: 100%;
  }
`;

const WrapperForm = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  & > div {
    width: 100%;
    max-width: 300px;
    position: relative;
    top: -30px;
  }

  h2,
  p {
    text-align: center;
  }

  p {
    margin: 10px 0;
    color: #98a0a7;
  }

  .form_buttons {
    text-align: center;
  }
`;

const FormField = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 0;

  label {
    font-weight: 700;
  }

  input {
    height: 30px;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0px 4px 4px var(--shadow);
    border: none;
    font-size: 13rem;
    padding-left: 10px;
  }

  input:focus {
    outline: 2px solid #98a0a7;
  }
`;

/* const WrapperSocialRegistry = styled.div`
  display: flex;
  justify-content: space-between;

  button {
    width: 45%;
  }
`; */

const WrapperConditions = styled.div`
  display: flex;
  align-items: center;
  justify-content: right;
`;

const ShowPass = styled.div`
  text-align: right;
  margin-bottom: 10px;
  label {
    color: var(--secondaryText);
  }
`;

/* const PlayerWrapper = styled.div`
  display: none;
  height: 100vh;
  filter: opacity(0.2);
  position: absolute;
  right: -100px;
  overflow: hidden;
  z-index: -1;

  img {
    height: 100%;
  }

  @media (min-width: 800px) {
    display: block;
  }
`; */

const Login = () => {
  const [errorMessage, setErrorMessage] = useState();
  const [passwordShown, setPasswordShown] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [signInData, setSignInData] = useState({
    email: '',
    password: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSignInData({
      ...signInData,
      [name]: value,
    });
  };

  const togglePassword = (e) => {
    e.target.checked ? setPasswordShown(true) : setPasswordShown(false);
  };

  const navigate = useNavigate();

  const submitLogin = async (e) => {
    e.preventDefault();
    const offset = new Date().getTimezoneOffset();
    const formatted = -(offset / 60);

    try {
      let authresult = await axios.post(
        `${process.env.REACT_APP_BASE_URL}auth/login/`,
        signInData
      );
      localStorage.setItem('user', JSON.stringify(authresult));
      localStorage.setItem(
        'GMT',
        `Etc/GMT${formatted < 0 ? '+' : null}${-formatted}`
      );
      navigate('/');
    } catch (err) {
      setErrorMessage(err.response.data.message);
    }
  };

  return (
    <WrapperLeft>
      {/* <PlayerWrapper>
        <img src={FootballPlayer} alt="" />
      </PlayerWrapper> */}
      <div>
        <WrapperLogo>
          <img src={GoChallengeLogo} alt="Logo de Go Challenge" />
        </WrapperLogo>
      </div>
      <WrapperForm>
        <div>
          <h2>Ingreso al dashboard administrativo</h2>
          <form className="form" onSubmit={submitLogin}>
            <FormField>
              <label htmlFor="email">Correo *</label>
              <input
                type="text"
                name="email"
                value={signInData.email}
                placeholder="Escribe tu correo electronico"
                autoComplete="off"
                onChange={handleChange}
                required
              />
            </FormField>
            <FormField>
              <label htmlFor="password">Contraseña *</label>
              <input
                type={passwordShown ? 'text' : 'password'}
                name="password"
                value={signInData.password}
                placeholder="Escribe tu contraseña"
                autoComplete="off"
                onChange={handleChange}
                required
              />
            </FormField>
            <ShowPass>
              <input type="checkbox" name="show" onClick={togglePassword} />
              <label htmlFor="show">Mostrar contraseña</label>
            </ShowPass>
            {errorMessage ? (
              <p
                style={{
                  backgroundColor: 'var(--warning)',
                  borderRadius: '5px',
                  color: '#fff',
                  fontWeight: '700',
                }}
              >
                {errorMessage}
              </p>
            ) : null}
            <div className="form_buttons">
              <Button backgroundColor="#394a58" color="#fff" text="Entrar">
                Entrar
              </Button>
            </div>
          </form>
          <div>
            <p>
              Olvidaste tu contraseña?{' '}
              <a
                onClick={(e) => {
                  e.preventDefault();
                  setOpenModal(true);
                }}
                href="/"
              >
                Recuperar contraseña
              </a>
            </p>
          </div>
        </div>
      </WrapperForm>
      <WrapperConditions>
        <p>Copyright © 2022 | GoChallenge | Todos los Derechos Reservados</p>
      </WrapperConditions>
      {openModal && (
        <RecoverPassModal
          openModal={openModal}
          setOpenModal={setOpenModal}
          userId={1}
        />
      )}
    </WrapperLeft>
  );
};

export default Login;
