import { useState, useEffect } from 'react';
import axiosInstance from '../../utils/axios';
import styled from 'styled-components';
import ActionButton from '../../components/atoms/actionButtons';
import ProfileImage from '../../assets/images/profile.jpg';
import AcceptIdentity from './acceptIdentity';
import RejectIdentity from './rejectIdentity';
import EditUser from './editUser';
import BlockIcon from '@mui/icons-material/Block';
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
import ImageIcon from '@mui/icons-material/Image';
import FsLightbox from 'fslightbox-react';

const WrapperUserStyle = styled.div`
  background-color: #fff;
  margin: 20px;
  padding: 20px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: space-between;

  .userData {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .userData__user {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-right: 10px;
  }

  .userData__identity {
    color: var(--accent);
    font-weight: 700;

    span {
      font-weight: 300;
      color: var(--secondaryText);
    }
  }

  .actionButtons {
    display: flex;
    gap: 5px;
  }
`;

const Icon = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const WrapperUser = ({ user, refreshData, filter, errorList }) => {
  const [acceptIdentityModal, setAcceptIdentityModal] = useState(false);
  const [rejectIdentityModal, setRejectIdentityModal] = useState(false);
  const [editUserModal, setEditUserModal] = useState(false);
  const [toggler, setToggler] = useState(false);

  return (
    <WrapperUserStyle>
      <div className="userData">
        <div className="userData__user">
          <Icon>
            <img src={user.avatar === '' ? ProfileImage : user.avatar} />
          </Icon>
          <p style={{ fontWeight: '700' }}>
            {user.first_name === ''
              ? user.username
              : `${user.first_name} ${user.last_name}`}
            <br />
            {user.identity_verification_status === 'verified' ? (
              <span style={{ fontWeight: '300', color: 'green' }}>
                Verificado
              </span>
            ) : (
              <span style={{ fontWeight: '300', color: 'var(--warning)' }}>
                Sin verificar
              </span>
            )}
          </p>
        </div>
        {/* <div className="userData__identity" style={{ marginRight: '20px' }}>
          <p>
            {user.identity.document_type.name} <br />
            <span>{user.identity.document_number}</span>
          </p>
        </div> */}
        {/* {user.status === 'waiting_for_user' ? (
          <div>
            <p style={{ color: 'red', fontWeight: '500' }}>{user.error}</p>
          </div>
        ) : null} */}
      </div>
      <div className="actionButtons">
        {/* <ActionButton
          text={
            <ImageIcon
              style={{ width: '25px', height: '25px', color: '#fff' }}
            />
          }
          color="#fff"
          backgroundColor="var(--primary)"
          onClick={() => setToggler(!toggler)}
        /> */}
        <ActionButton
          text={
            <EditIcon
              style={{ width: '25px', height: '25px', color: '#fff' }}
            />
          }
          color="#fff"
          backgroundColor="#39cb6b"
          onClick={() => setEditUserModal(true)}
        />
        <ActionButton
          text={
            filter === 'blocked' ? (
              <CheckIcon
                style={{ width: '25px', height: '25px', color: '#fff' }}
              />
            ) : (
              <BlockIcon
                style={{ width: '25px', height: '25px', color: '#fff' }}
              />
            )
          }
          color="#fff"
          backgroundColor={filter !== 'blocked' ? 'red' : 'green'}
          onClick={() =>
            filter === 'unblocked'
              ? setRejectIdentityModal(true)
              : setAcceptIdentityModal(true)
          }
          // onClick={blockUser}
        />
      </div>
      {/* <FsLightbox
        toggler={toggler}
        sources={[
          user.identity.document_file[0],
          user.identity.document_file[1],
        ]}
      /> */}
      {acceptIdentityModal && (
        <AcceptIdentity
          openModal={acceptIdentityModal}
          setOpenModal={setAcceptIdentityModal}
          user={user}
          refreshData={refreshData}
        />
      )}
      {rejectIdentityModal && (
        <RejectIdentity
          openModal={rejectIdentityModal}
          setOpenModal={setRejectIdentityModal}
          user={user}
          refreshData={refreshData}
          errorList={errorList}
        />
      )}
      {editUserModal && (
        <EditUser
          openModal={editUserModal}
          setOpenModal={setEditUserModal}
          user={user}
          refreshData={refreshData}
        />
      )}
    </WrapperUserStyle>
  );
};

export default WrapperUser;
