import styled from 'styled-components';
import Button from '../../atoms/button';

const Wrapper = styled.div`
  max-width: 800px;
  .textSecondary {
    color: var(--secondaryText);
  }

  & > div {
    margin-bottom: 40px;
  }

  div:last-child {
    margin-bottom: 0;
  }

  label {
    display: flex;
    align-items: center;
  }

  input {
    width: 20px;
    height: 20px;
  }

  .search_options div {
    margin-top: 10px;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 2fr repeat(3, 1fr);
  grid-gap: 10px;
  justify-items: center;

  .align_left {
    justify-self: start;
  }
`;

const Privacy = () => {
  return (
    <Wrapper>
      <div>
        <h2>Privacidad</h2>
        <p className="textSecondary">
          Selecciona quien te puede buscar y retar en la plataforma
        </p>
      </div>
      <Grid>
        <div></div>
        <div>Amigos</div>
        <div>Amigos de amigos</div>
        <div>Cualquiera</div>
        <div className="align_left">
          <label htmlFor="solicitudes_amistad">
            Quien puede enviarte solicitudes de amistad?
          </label>
        </div>
        <div>
          <input type="checkbox" name="solicitudes_amistad" />
        </div>
        <div>
          <input type="checkbox" name="solicitudes_amistad" />
        </div>
        <div>
          <input type="checkbox" name="solicitudes_amistad" />
        </div>
        <div className="align_left">
          <label htmlFor="solicitudes_retos">
            Quien puede enviarte solicitudes de retos?
          </label>
        </div>
        <div>
          <input type="checkbox" name="solicitudes_retos" />
        </div>
        <div>
          <input type="checkbox" name="solicitudes_retos" />
        </div>
        <div>
          <input type="checkbox" name="solicitudes_retos" />
        </div>
        <div className="align_left">
          <label htmlFor="mensajes">
            Quien puede enviarte mensajes directos?
          </label>
        </div>
        <div>
          <input type="checkbox" name="mensajes" />
        </div>
        <div>
          <input type="checkbox" name="mensajes" />
        </div>
        <div>
          <input type="checkbox" name="mensajes" />
        </div>
      </Grid>
      <div className="search_options">
        <p>Como deseas que te puedan encontrar en las búsquedas?</p>
        <p className="textSecondary">
          Los otros usuarios pueden buscarte escribiendo tu...
        </p>
        <div>
          <label>
            <input type="checkbox" name="busquedas" value="Correo" /> Correo
          </label>
        </div>
        <div>
          <label>
            <input type="checkbox" name="busquedas" /> Nombre
          </label>
        </div>
        <div>
          <label>
            <input type="checkbox" name="busquedas" /> Apodo
          </label>
        </div>
      </div>
      <div>
        <Button
          text="Guardar cambios"
          color="var(--primaryBackground)"
          backgroundColor="var(--button)"
        />
      </div>
    </Wrapper>
  );
};

export default Privacy;
