import React from 'react';
import Button from '../atoms/button';
import styled from 'styled-components';
import SearchIcon from '@mui/icons-material/Search';

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 20px;
  margin-right: 20px;
`;

const WrapperSearch = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  width: 50%;

  input {
    background: transparent;
    box-shadow: none;
    border: none;
    width: 100%;
    max-width: 600px;
    height: 50px;
    color: #fff;

    &:focus {
      outline: none;
    }
  }
`;

const WrapperBuy = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  color: #fff;
`;

const Searchbar = () => {
  return (
    <Wrapper>
      <WrapperSearch>
        <SearchIcon style={{ width: '30px', height: '30px', color: '#fff' }} />
        <input
          type="search"
          name="search"
          placeholder="Buscar amigos o eventos"
        />
      </WrapperSearch>
      <WrapperBuy>
        <p>Valor actual: 150$</p>
        <Button
          text="Depositar GCC"
          color="#394A58"
          backgroundColor="#FADC41"
        />
      </WrapperBuy>
    </Wrapper>
  );
};

export default Searchbar;
