import { Navigate, Outlet } from 'react-router-dom';
//import { useAuthContext } from '../contexts/AuthContext';

function RequireAuth({ children }) {
  // const auth = useAuthContext();
  const auth = localStorage.getItem('user');
  const data = JSON.parse(auth);
  return data?.data.access_token ? <Outlet /> : <Navigate to="/login" />;
}

export default RequireAuth;
