import { useState } from 'react';
import Main from '../../layouts/main';
import styled from 'styled-components';
import Button from '../../components/atoms/button';
import AddSportModal from './AddSportModal';
import AddLeagueModal from './AddLeagueModal';
import AddTeamModal from './AddTeamModal';
import AddEventModal from './AddEventModal';
import SideComponent from './sideComponent';
import LiveEvents from './liveEvents';
import FinishedEvents from './finishedEvents';
import FinishedEventsWithoutResults from './finishedEventsWithoutResults';
import UpcomingEvents from './upcomingEvents';

const Wrapper = styled.div`
  background-color: var(--primaryBackground);
  display: flex;
  height: 100%;
  .main {
    width: 100%;
    height: calc(100vh - 140px);
    overflow-y: scroll;

    .addButtonsBox {
      padding: 20px;
      display: flex;
      justify-content: space-around;
    }
  }

  .info {
    background-color: var(--secondaryBackground);
    width: 500px;
    height: calc(100vh - 140px);
    overflow-y: auto;
  }
`;

const Dashboard = () => {
  const [openSportModal, setOpenSportModal] = useState(false);
  const [openLeagueModal, setOpenLeagueModal] = useState(false);
  const [openTeamModal, setOpenTeamModal] = useState(false);
  const [openEventModal, setOpenEventModal] = useState(false);

  return (
    <Main>
      <Wrapper>
        <div className="main">
          <div className="addButtonsBox">
            <Button
              text="Agregar deporte"
              color="#fff"
              backgroundColor="#324250"
              onClick={(e) => {
                e.preventDefault();
                setOpenSportModal(true);
              }}
            />
            <Button
              text="Agregar liga"
              color="#fff"
              backgroundColor="#324250"
              onClick={(e) => {
                e.preventDefault();
                setOpenLeagueModal(true);
              }}
            />
            <Button
              text="Agregar equipo"
              color="#fff"
              backgroundColor="#324250"
              onClick={(e) => {
                e.preventDefault();
                setOpenTeamModal(true);
              }}
            />
            <Button
              text="Agregar evento"
              color="#fff"
              backgroundColor="#324250"
              onClick={(e) => {
                e.preventDefault();
                setOpenEventModal(true);
              }}
            />
          </div>
          <div className="events">
            <FinishedEventsWithoutResults />
            <LiveEvents />
            <UpcomingEvents />
            <FinishedEvents />
          </div>
        </div>
        <div className="info">
          <SideComponent />
        </div>
      </Wrapper>
      {openSportModal && (
        <AddSportModal
          openModal={openSportModal}
          setOpenModal={setOpenSportModal}
        />
      )}
      {openLeagueModal && (
        <AddLeagueModal
          openModal={openLeagueModal}
          setOpenModal={setOpenLeagueModal}
        />
      )}
      {openTeamModal && (
        <AddTeamModal
          openModal={openTeamModal}
          setOpenModal={setOpenTeamModal}
        />
      )}
      {openEventModal && (
        <AddEventModal
          openModal={openEventModal}
          setOpenModal={setOpenEventModal}
        />
      )}
    </Main>
  );
};

export default Dashboard;
