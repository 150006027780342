import React, { useState } from 'react';
import styled from 'styled-components';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import ActionButton from '../../atoms/actionButtons';
import EditRolModal from './rols/editRolModal';
import DeleteRolModal from './rols/deleteRolModal';

const ActionButtonWrapper = styled.div`
  display: flex;
  gap: 10px;
  justify-content: center;
`;

const Button = styled.button`
  width: 30px;
  height: 30px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    filter: opacity(50%);
  }
`;

const EditButton = styled(Button)`
  background-color: #5bc6e8;
`;

const DeleteButton = styled(Button)`
  background-color: #ff5353;
`;

const RolsListItem = ({ rolData, refreshData }) => {
  const [openEditRolModal, setOpenEditRolModal] = useState(false);
  const [openDeleteRolModal, setOpenDeleteRolModal] = useState(false);

  return (
    <tr>
      <td style={{ fontWeight: '700' }}>{rolData.name}</td>
      <td>{rolData.description}</td>
      <td>
        <ActionButtonWrapper>
          <ActionButton
            text={
              <EditIcon
                style={{ width: '25px', height: '25px', color: '#fff' }}
              />
            }
            color="#fff"
            backgroundColor="#5bc6e8"
            onClick={(e) => {
              e.preventDefault();
              setOpenEditRolModal(true);
            }}
          />
          <ActionButton
            text={
              <DeleteForeverIcon
                style={{ width: '25px', height: '25px', color: '#fff' }}
              />
            }
            color="#fff"
            backgroundColor="#ff5353"
            onClick={(e) => {
              e.preventDefault();
              setOpenDeleteRolModal(true);
            }}
          />
        </ActionButtonWrapper>
      </td>
      {openEditRolModal && (
        <EditRolModal
          openModal={openEditRolModal}
          setOpenModal={setOpenEditRolModal}
          rolData={rolData}
          refreshData={refreshData}
        />
      )}
      {openDeleteRolModal && (
        <DeleteRolModal
          openModal={openDeleteRolModal}
          setOpenModal={setOpenDeleteRolModal}
          rolData={rolData}
          refreshData={refreshData}
        />
      )}
    </tr>
  );
};

export default RolsListItem;
