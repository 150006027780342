import { useState, useEffect } from 'react';
import styled from 'styled-components';
import FormField from '../../atoms/FormField';
import Button from '../../atoms/button';
import axiosInstance from '../../../utils/axios';
import { toast } from 'react-toastify';

const Wrapper = styled.div`
  .textSecondary {
    color: var(--secondaryText);
  }

  & > div {
    margin-bottom: 40px;
  }

  div:last-child {
    margin-bottom: 0;
  }
`;

const Form = styled.form`
  .profilePicture {
    display: flex;
    align-items: center;

    span {
      font-size: 12rem;
    }

    input {
      width: 0.1px;
      height: 0.1px;
    }

    input::before {
      content: '+';
      position: absolute;
      cursor: pointer;
      font-weight: 700;
      font-size: 50rem;
      color: var(--primary);
      top: 0;
      left: 0;
      width: 60px;
      height: 60px;
      text-align: center;
      line-height: 60px;
    }

    .profile_picture {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      position: relative;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  div {
    flex-direction: row;

    label {
      width: 200px;
    }

    input {
      width: 100%;
      max-width: 400px;
    }
  }
`;

const InputFileWrapper = styled.div`
  display: flex;
  width: 400px;

  button {
    color: #ff5353;
    font-weight: 700;
    font-size: 12rem;
    margin-left: 20px;
  }
`;

const Profile = () => {
  const [siteConfigData, setSiteConfigData] = useState({
    gcc_to_currency: 1.0,
    global_challenge_cap: 1000,
  });
  const [updatedData, setUpdatedData] = useState({});
  const [selectedFile, setSelectedFile] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUpdatedData({
      ...updatedData,
      [name]: value,
    });
  };

  const getUserData = async () => {
    try {
      const { data } = await axiosInstance.get(
        `${process.env.REACT_APP_BASE_URL}site_config/`
      );
      setSiteConfigData(data);
    } catch (error) {
      // toast.success('Hubo un error aceptando el reto');
      console.log(error.response.data.detail.name);
    }
  };

  const submitUserUpdate = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axiosInstance.put(
        `${process.env.REACT_APP_BASE_URL}site_config/update/`,
        updatedData
      );
      toast.success('Datos actualizados con exito');
    } catch (error) {
      // console.log(error.response.data.username[0]);
    }
  };

  useEffect(() => {
    getUserData();
  }, []);

  return (
    <Wrapper>
      <div>
        <h2>Administra los GCC</h2>
        <p className="textSecondary">
          Actualiza aca todo lo referente al manejo y limites de los GCC
        </p>
      </div>
      <div>
        <Form>
          <FormField
            type="text"
            name="gcc_to_currency"
            value={
              updatedData.gcc_to_currency || siteConfigData.gcc_to_currency
            }
            placeholder="Escribe el valor actual del GCC"
            labelText="Valor del GCC"
            onChange={handleChange}
          />
          <FormField
            type="text"
            name="global_challenge_cap"
            value={
              updatedData.global_challenge_cap ||
              siteConfigData.global_challenge_cap
            }
            placeholder="Escribe el monto tope de apuesta para los retos"
            labelText="Limite del monto para los retos"
            onChange={handleChange}
          />
        </Form>
      </div>
      <div>
        <Button
          text="Guardar cambios"
          color="#fff"
          backgroundColor="#324250"
          onClick={submitUserUpdate}
        />
      </div>
    </Wrapper>
  );
};

export default Profile;
