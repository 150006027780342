import React from 'react';
import styled from 'styled-components';
import NavbarComponent from '../components/templates/navbar';
import SearchbarComponent from '../components/templates/searchbar';

const Wrapper = styled.div`
  height: 100vh;
  display: grid;
  grid-template-rows: 100px 1fr 40px;
`;

const Navbar = styled.div``;

const MainContent = styled.div`
  height: 100%;
`;

const Footer = styled.div`
  background-color: #fadc41;
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    font-size: 12px;
  }
`;

const Main = (props) => {
  return (
    <Wrapper>
      <Navbar>
        <NavbarComponent />
      </Navbar>
      <MainContent>{props.children}</MainContent>
      <Footer>
        Copyright © 2022 | GoChallenge | Todos los Derechos Reservados
      </Footer>
    </Wrapper>
  );
};

export default Main;
