import React, { useState, useEffect } from 'react';
import axiosInstance from '../../../utils/axios';
import styled from 'styled-components';
import Button from '../../atoms/button';
import RolsListItem from './RolsListItem';
import AddRolModal from './rols/newRolModal';

const Wrapper = styled.div`
  .textSecondary {
    color: var(--secondaryText);
  }

  & > div {
    margin-bottom: 40px;
  }

  div:last-child {
    margin-bottom: 0;
  }

  .rols_header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;

  thead {
    border-bottom: solid 3px #fadc41;
  }

  tr {
    position: relative;
  }

  tr:hover:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background-color: #fff;
    border-radius: 5px;
  }

  th,
  td {
    text-align: center;
    padding: 20px;
  }
`;

const Rols = () => {
  const [rolesList, setRolesList] = useState([]);

  const [openNewRolModal, setOpenNewRolModal] = useState(false);

  const getRolsList = async () => {
    try {
      const { data } = await axiosInstance.get(
        `${process.env.REACT_APP_BASE_URL}auth/user_roles/`
      );

      setRolesList(data.results);
    } catch (error) {
      console.log(error.response.data.detail.name);
    }
  };

  useEffect(() => {
    getRolsList();
  }, []);
  return (
    <Wrapper>
      <div className="rols_header">
        <div>
          <h2>Roles</h2>
          <p className="textSecondary">
            Crea y edita los roles de la plataforma
          </p>
        </div>
        <div>
          <Button
            text="Crear rol"
            color="#fff"
            backgroundColor="#324250"
            onClick={(e) => {
              e.preventDefault();
              setOpenNewRolModal(true);
            }}
          />
        </div>
      </div>
      <Table>
        <thead>
          <tr>
            <th>Nombre del rol</th>
            <th>Descripción</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {rolesList?.map((item) => {
            return (
              <RolsListItem
                rolData={item}
                key={item.id}
                refreshData={getRolsList}
              />
            );
          })}
        </tbody>
      </Table>
      {openNewRolModal && (
        <AddRolModal
          openModal={openNewRolModal}
          setOpenModal={setOpenNewRolModal}
          refreshData={getRolsList}
        />
      )}
    </Wrapper>
  );
};

export default Rols;
