import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import axiosInstance from '../../../utils/axios';
import FormField from '../../atoms/FormField';
import Modal from '../modal';
import ModalLayout from '../modal/ModalLayout';
import Button from '../../atoms/button';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import { ListItem } from '@mui/material';

const icon = <CheckBoxOutlineBlankIcon fontSize="large" />;
const checkedIcon = <CheckBoxIcon fontSize="large" />;

const CustomAutocomplete = styled(Autocomplete)`
  margin-top: 5px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 4px 4px -4px var(--shadow);
  color: red;

  fieldset {
    border-color: transparent;
  }

  *,
  li {
    font-size: 16rem !important;
  }
`;

const CustomCheckbox = styled(Checkbox)`
  width: 30px;
  height: 30px;
  * {
    font-size: 24rem !important;
  }
`;

const Wrapper = styled.div`
  text-align: center;
  width: 90%;
  max-width: 400px;
  background-color: var(--primaryBackground);
  border-radius: 5px;
  overflow: hidden;

  h3 {
    color: var(--primary);
    font-size: 15rem;
  }

  .title {
    background-color: #fff;
    border-bottom: solid var(--accent) 5px;
    text-align: left;
    padding: 20px;

    p {
      color: var(--secondaryText);
    }
  }

  .body {
    padding: 20px 40px 40px 40px;
  }

  input {
    max-width: unset;
  }

  input[type='file'] {
    background-color: transparent;
    box-shadow: unset;
  }
  .exampleBox {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    color: var(--primaryText);
    font-weight: 700;

    .iconExample {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      overflow: hidden;

      img {
        width: 100%;
      }
    }
  }
  .message {
    margin-bottom: 15px;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 2fr repeat(4, 1fr);
  grid-gap: 10px;
  justify-items: center;

  .align_left {
    justify-self: start;
  }
`;

const ConfirmDeleteModal = ({ openModal, setOpenModal, refreshData }) => {
  const ModalLayoutRef = useRef();

  /* const handleCreateEvent = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axiosInstance.post(
        `${process.env.REACT_APP_BASE_URL}auth/user_roles/create/`,
        {
          name: newRol.name,
          description: newRol.description,
          permission_list: test,
        }
      );
      toast.success('Evento creado');
      setOpenModal(false);
      refreshData();
    } catch (error) {
      // setMessage(error.response.data.detail.error[0]);
    }
  }; */

  return (
    <Modal
      isOpen={openModal}
      element={
        <ModalLayout
          ref={ModalLayoutRef}
          myOwnContainer
          setOpenModal={setOpenModal}
        >
          <Wrapper>
            <div className="title">
              <h2>Agregar nuevo rol</h2>
              <p>Agrega un nuevo rol para usuarios de la plataforma</p>
            </div>
            <div className="body">
              <form>
                <h3>Permisos</h3>

                <Button
                  text="Agregar rol"
                  color="#fff"
                  backgroundColor="var(--primary)"
                  //onClick={handleCreateEvent}
                />
              </form>
            </div>
          </Wrapper>
        </ModalLayout>
      }
    />
  );
};

export default ConfirmDeleteModal;
