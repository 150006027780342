import { useRef } from 'react';
import styled from 'styled-components';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import FacebookIcon from '@mui/icons-material/Facebook';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EmailIcon from '@mui/icons-material/Email';
import FriendsListItem from './FriendsListItem';

const Wrapper = styled.div`
  display: flex;
  gap: 80px;
  flex-wrap: wrap;

  .textSecondary {
    color: var(--secondaryText);
  }
`;

const WrapperFriends = styled.div``;

const WrapperInvite = styled.div`
  & > div {
    margin-bottom: 40px;
  }

  .social_buttons {
    button {
      margin-right: 20px;
    }
  }

  .invite_code {
    & > div {
      display: flex;
      gap: 10px;
    }
    p {
      font-size: 30rem;
    }
  }
`;

const Friends = () => {
  const inviteCode = useRef('');

  /* Function to copy the invitation code */
  const copyInviteCode = () => {
    /* Get the text field */
    var copyText = inviteCode.current.innerText;

    /* Copy the text to the clipboard */
    navigator.clipboard.writeText(copyText);

    /* Alert the copied text */
    alert('Se copio tu código de invitación: ' + copyText);
  };

  return (
    <Wrapper>
      <WrapperFriends>
        <div>
          <h2>Amigos</h2>
          <p className="textSecondary">Ve el listado de amigos aquí</p>
        </div>
        <div>
          <div>
            <FriendsListItem />
            <FriendsListItem />
            <FriendsListItem />
          </div>
        </div>
        <div>
          <h3>Amigos bloqueados</h3>
        </div>
        <div>
          <div>
            <FriendsListItem />
          </div>
        </div>
      </WrapperFriends>
      <WrapperInvite>
        <div>
          <h2>Invitar amigos</h2>
          <p className="textSecondary">
            Invita a tus amigos a que se unan y retales
          </p>
          <div className="social_buttons">
            <button>
              <FacebookIcon
                style={{ width: '30px', height: '30px', color: '#3C86F4' }}
              />
            </button>
            <button>
              <WhatsAppIcon
                style={{ width: '30px', height: '30px', color: '#48C857' }}
              />
            </button>
            <button>
              <EmailIcon
                style={{ width: '30px', height: '30px', color: '#394A58' }}
              />
            </button>
          </div>
        </div>
        <div>
          <h2>Código de invitación</h2>
          <p className="textSecondary">
            Comparte este código de invitación con tus amigos y gana GCC{' '}
          </p>
          <div className="invite_code">
            <div>
              <p className="textSecondary" ref={inviteCode}>
                6f44cd
              </p>
              <button onClick={copyInviteCode}>
                <ContentCopyIcon
                  style={{ width: '30px', height: '30px', color: '#394A58' }}
                />
              </button>
            </div>
          </div>
        </div>
      </WrapperInvite>
    </Wrapper>
  );
};

export default Friends;
