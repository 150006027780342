import Modal from '../../modal';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import ModalLayout from '../../modal/ModalLayout';
import FormField from '../../../atoms/FormField';
import Button from '../../../atoms/button';
import { useState, useRef, useEffect } from 'react';
import axiosInstance from '../../../../utils/axios';

import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const Wrapper = styled.div`
  text-align: center;
  width: 90%;
  max-width: 400px;
  background-color: var(--primaryBackground);
  border-radius: 5px;
  overflow: hidden;

  .title {
    background-color: #fff;
    border-bottom: solid var(--accent) 5px;
    text-align: left;
    padding: 20px;

    p {
      color: var(--secondaryText);
    }
  }

  .body {
    padding: 20px;
  }

  input {
    max-width: unset;
  }

  input[type='file'] {
    background-color: transparent;
    box-shadow: unset;
  }
  .exampleBox {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    color: var(--primaryText);
    font-weight: 700;

    .iconExample {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      overflow: hidden;

      img {
        width: 100%;
      }
    }
  }
  .message {
    margin-bottom: 15px;
  }
`;

const DeleteRolModal = ({ openModal, setOpenModal, refreshData, rolData }) => {
  const ModalLayoutRef = useRef();
  const [errorMessage, setErrorMessage] = useState();

  const handleDeleteRol = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axiosInstance.delete(
        `${process.env.REACT_APP_BASE_URL}auth/user_roles/${rolData.id}/`
      );
      toast.success('Rol eliminado');
      setOpenModal(false);
      refreshData();
    } catch (error) {
      setErrorMessage(error.response.data[0]);
    }
  };

  return (
    <Modal
      isOpen={openModal}
      element={
        <ModalLayout
          ref={ModalLayoutRef}
          myOwnContainer
          setOpenModal={setOpenModal}
        >
          <Wrapper>
            <div className="title">
              <h2>Eliminar el rol de {rolData.name}</h2>
              <p>Esta seguro que desea eliminar el rol?</p>
            </div>
            <div className="body">
              {errorMessage ? (
                <p
                  style={{
                    backgroundColor: 'var(--warning)',
                    borderRadius: '5px',
                    padding: '5px',
                    color: '#fff',
                    fontWeight: '700',
                    marginBottom: '20px',
                  }}
                >
                  {errorMessage}
                </p>
              ) : null}
              <Button
                text="Eliminar rol"
                color="#fff"
                backgroundColor="var(--primary)"
                onClick={handleDeleteRol}
              />
            </div>
          </Wrapper>
        </ModalLayout>
      }
    />
  );
};

export default DeleteRolModal;
