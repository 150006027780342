import { useState } from 'react';
import styled from 'styled-components';
import Button from '../../../atoms/button';
import ListItem from './ListItem';
import AddSportModal from '../../../../pages/dashboard/AddSportModal';
import FormField from '../../../atoms/FormField';

import { useEffect } from 'react';
import axiosInstance from '../../../../utils/axios';

const Wrapper = styled.div`
  .textSecondary {
    color: var(--secondaryText);
  }

  & > div {
    margin-bottom: 40px;
  }

  div:last-child {
    margin-bottom: 0;
  }

  .rols_header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;

  tr {
    position: relative;
  }

  tr:hover:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background-color: #fff;
    border-radius: 5px;
  }

  thead {
    border-bottom: solid 3px #fadc41;
  }

  th,
  td {
    text-align: center;
    padding: 20px;
  }
`;

const SportsAdmin = () => {
  const [openSportModal, setOpenSportModal] = useState(false);
  const [sportsList, setSportsList] = useState([]);
  const [search, setSearch] = useState('');

  const getSportsList = async () => {
    try {
      const { data } = await axiosInstance.get(
        `${process.env.REACT_APP_BASE_URL}sports/`
      );
      setSportsList(data);
    } catch (error) {
      console.log(error.response.data.detail.name);
    }
  };

  useEffect(() => {
    getSportsList();
  }, []);

  return (
    <Wrapper>
      <div className="rols_header">
        <div>
          <h2>Administrar deportes</h2>
          <p className="textSecondary">
            Crea y edita los deportes de la plataforma
          </p>
        </div>
        <div>
          <Button
            text="Crear deporte"
            color="#fff"
            backgroundColor="#324250"
            onClick={(e) => {
              e.preventDefault();
              setOpenSportModal(true);
            }}
          />
        </div>
      </div>
      <FormField
        type="search"
        name="name"
        labelText="Buscar deporte"
        value={search}
        onChange={(e) => {
          setSearch(e.target.value);
        }}
      />
      <Table>
        <thead>
          <tr>
            <th style={{ width: '30%' }}>Nombre del deporte</th>
            <th>Icono</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {sportsList
            .filter((item) => {
              return item.name.toLowerCase().includes(search);
            })
            .map((sport) => {
              return (
                <ListItem
                  sport={sport}
                  key={sport.id}
                  refreshData={getSportsList}
                />
              );
            })}
        </tbody>
      </Table>
      {openSportModal && (
        <AddSportModal
          openModal={openSportModal}
          setOpenModal={setOpenSportModal}
          refreshData={getSportsList}
        />
      )}
    </Wrapper>
  );
};

export default SportsAdmin;
