import styled from 'styled-components';
import Modal from '../../../components/templates/modal/';
import ModalLayout from '../../../components/templates/modal/ModalLayout';
import Button from '../../../components/atoms/button';
import { useState, useRef } from 'react';
import axiosInstance from '../../../utils/axios';

const Wrapper = styled.div`
  text-align: center;
  width: 90%;
  max-width: 400px;
  background-color: var(--primaryBackground);
  border-radius: 5px;
  overflow: hidden;

  .title {
    background-color: #fff;
    text-align: left;
    padding: 20px;

    p {
      color: var(--secondaryText);
    }
  }

  .body {
    padding: 20px;
  }

  input {
    max-width: unset;
  }

  input[type='file'] {
    background-color: transparent;
    box-shadow: unset;
  }
  .exampleBox {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    color: var(--primaryText);
    font-weight: 700;

    .iconExample {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      overflow: hidden;

      img {
        width: 100%;
      }
    }
  }
  .message {
    margin-bottom: 15px;
  }
`;

const Dropdown = styled.div`
  label {
    font-weight: 700;
  }

  select {
    height: 30px;
    width: 100%;
    border-radius: 10px;
    background-color: var(--secondaryBackground);
    box-shadow: 0px 4px 4px -4px var(--shadow);
    border: none;
    font-size: 13rem;
    padding: 0 10px;
    margin-top: 5px;
    max-width: 400px;
  }
`;

const DeleteEventModal = ({
  openModal,
  setOpenModal,
  eventData,
  refreshData,
}) => {
  const [message, setMessage] = useState('');
  const ModalLayoutRef = useRef();

  const HandleDeleteSport = async (e) => {
    e.preventDefault();
    try {
      let authresult = await axiosInstance.get(
        `${process.env.REACT_APP_BASE_URL}events/${eventData.id}/disable/`
      );
      setOpenModal(false);
      refreshData();
    } catch (err) {
      setMessage(err.response.data.detail);
    }
  };

  const enableSport = async (e) => {
    e.preventDefault();
    try {
      let authresult = await axiosInstance.get(
        `${process.env.REACT_APP_BASE_URL}teams/${eventData.id}/enable/`
      );
      setOpenModal(false);
      refreshData();
    } catch (err) {
      setMessage(err.response.data.detail);
    }
  };

  return (
    <Modal
      isOpen={openModal}
      element={
        <ModalLayout
          ref={ModalLayoutRef}
          myOwnContainer
          setOpenModal={setOpenModal}
        >
          <Wrapper>
            <div className="title">
              <h2>
                Estas a punto de{' '}
                {eventData.status ? 'inhabilitar' : 'habilitar'}{' '}
                {eventData.team1.name} vs {eventData.team2.name}, deseas
                continuar?
              </h2>
              <p>Ten en cuenta que no podras habilitarlo de nuevo</p>
            </div>
            <div className="body">
              {message ? (
                <p
                  style={{
                    backgroundColor: 'var(--warning)',
                    borderRadius: '5px',
                    padding: '5px',
                    color: '#fff',
                    fontWeight: '700',
                    marginBottom: '20px',
                  }}
                >
                  {message}
                </p>
              ) : null}
              <Button
                text={eventData.status ? 'Inhabilitar' : 'Habilitar'}
                color="#fff"
                backgroundColor="var(--primary)"
                onClick={eventData.status ? HandleDeleteSport : enableSport}
              />
            </div>
          </Wrapper>
        </ModalLayout>
      }
    />
  );
};

export default DeleteEventModal;
