import { useState, useEffect } from 'react';
import axiosInstance from '../../utils/axios';
import Main from '../../layouts/main';
import styled from 'styled-components';
import Button from '../../components/atoms/button';
import ActionButton from '../../components/atoms/actionButtons';
import SideComponent from '../dashboard/sideComponent';
import WrapperUser from './WrapperUser';

const Wrapper = styled.div`
  background-color: var(--primaryBackground);
  display: flex;
  height: 100%;
  .main {
    width: 100%;
    height: calc(100vh - 140px);
    overflow-y: scroll;

    .addButtonsBox {
      padding: 20px;
      display: flex;
      justify-content: space-around;
    }
  }

  .info {
    background-color: var(--secondaryBackground);
    width: 500px;
    height: calc(100vh - 140px);
    overflow-y: auto;
  }
`;

const UserClients = () => {
  const [filter, setFilter] = useState('unblocked');
  const [errorList, setErrorList] = useState({
    error: [],
    status: [],
    verification_status: [],
  });
  const [userList, setUserList] = useState([]);

  /* const getUsersList = async () => {
    try {
      const { data } = await axiosInstance.get(
        `${process.env.REACT_APP_BASE_URL}client_management/`
      );
      setUserList(data);
    } catch (error) {
      console.log(error.response.data.detail.name);
    }
  }; */

  const getUsersList = async () => {
    try {
      const { data } = await axiosInstance.get(
        `${process.env.REACT_APP_BASE_URL}${
          filter === 'unblocked'
            ? 'auth/users/list/'
            : 'client_management/list_blocked_clients/'
        }`
      );
      setUserList(data);
    } catch (error) {
      console.log(error.response.data.detail.name);
    }
  };

  const verificationChoicesList = async () => {
    try {
      const { data } = await axiosInstance.get(
        `${process.env.REACT_APP_BASE_URL}identities/validate/choices/`
      );
      setErrorList(data);
    } catch (error) {
      console.log(error.response.data.detail.name);
    }
  };

  useEffect(() => {
    getUsersList();
    // verificationChoicesList();
  }, [filter]);

  return (
    <Main>
      <Wrapper>
        <div className="main">
          <div
            style={{
              margin: '20px',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Button
              text="Todos los clientes"
              color="#fff"
              backgroundColor="var(--primary)"
              onClick={() => setFilter('unblocked')}
            />
            <Button
              text="Clientes bloqueados"
              color="#fff"
              backgroundColor="var(--primary)"
              onClick={() => setFilter('blocked')}
            />
          </div>
          {userList
            // .filter((user) => user.user_role === '1')
            .map((user) => {
              return (
                <WrapperUser
                  key={user.id}
                  user={user}
                  refreshData={getUsersList}
                  filter={filter}
                  errorList={errorList}
                />
              );
            })}
        </div>
        <div className="info">
          <SideComponent />
        </div>
      </Wrapper>
    </Main>
  );
};

export default UserClients;
