import { BrowserRouter, Routes, Route } from 'react-router-dom';
import RequireAuth from './routes/requireAuth';
import SingIn from './pages/signin';
import Clients from './pages/usersClients';
import ManageClients from './pages/manageClients';
import ManageUsers from './pages/manageUsers';
import Profile from './pages/account/profile';
import Rols from './pages/account/Rols';
import SportsAdminPage from './pages/account/sports';
import LeaguesAdminPage from './pages/account/leaguesAdmin';
import TeamsAdminPage from './pages/account/teamsAdmin';
import Friends from './pages/account/friends';
import Privacy from './pages/account/privacy';
import DeleteAccount from './pages/account/deleteAccount';
import Dashboard from './pages/dashboard';
import EventsWithoutResults from './pages/eventsWithoutResults';
import LiveEventsPage from './pages/liveEventsPage';
import LoadEventsPage from './pages/loadMassiveEvents';
import NextEventsPage from './pages/nextEventsPage';
import FinishedEventsPage from './pages/finishedEventsPage';
import GCCManage from './pages/account/gccManage';
import './App.css';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<SingIn />} />
        <Route element={<RequireAuth />}>
          <Route path="account">
            <Route path="profile" element={<Profile />} />
            <Route path="roles" element={<Rols />} />
            <Route path="sports-admin" element={<SportsAdminPage />} />
            <Route path="leagues-admin" element={<LeaguesAdminPage />} />
            <Route path="teams-admin" element={<TeamsAdminPage />} />
            <Route path="amigos" element={<Friends />} />
            <Route path="gcc-manage" element={<GCCManage />} />
            <Route path="privacidad" element={<Privacy />} />
            <Route path="eliminar-cuenta" element={<DeleteAccount />} />
          </Route>
          <Route path="/" element={<Dashboard />} />
          <Route path="/clients" element={<Clients />} />
          <Route path="/manage-clients" element={<ManageClients />} />
          <Route path="/manage-users" element={<ManageUsers />} />
          <Route path="/pending-results" element={<EventsWithoutResults />} />
          <Route path="/live-events" element={<LiveEventsPage />} />
          <Route path="/next-events" element={<NextEventsPage />} />
          <Route path="/finished-events" element={<FinishedEventsPage />} />
          <Route path="/load-events" element={<LoadEventsPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
