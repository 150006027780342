import Modal from '../../../../components/templates/modal';
import axiosInstance from '../../../../utils/axios';
import styled from 'styled-components';
import ModalLayout from '../../../../components/templates/modal/ModalLayout';
import FormField from '../../../../components/atoms/FormField';
import Button from '../../../../components/atoms/button';
import { useState, useRef, useEffect } from 'react';
import { toast } from 'react-toastify';

const Wrapper = styled.div`
  text-align: center;
  width: 90%;
  max-width: 400px;
  background-color: var(--primaryBackground);
  border-radius: 5px;
  overflow: hidden;

  .title {
    background-color: #fff;
    border-bottom: solid var(--accent) 5px;
    text-align: left;
    padding: 20px;

    p {
      color: var(--secondaryText);
    }
  }

  .body {
    padding: 40px;
  }

  input {
    max-width: unset;
  }

  input[type='file'] {
    background-color: transparent;
    box-shadow: unset;
  }
  .exampleBox {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    color: var(--primaryText);
    font-weight: 700;

    .iconExample {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      overflow: hidden;

      img {
        width: 100%;
      }
    }
  }
  .message {
    margin-bottom: 15px;
  }
`;

const Dropdown = styled.div`
  label {
    font-weight: 700;
  }

  select {
    height: 30px;
    width: 100%;
    border-radius: 10px;
    background-color: var(--secondaryBackground);
    box-shadow: 0px 4px 4px -4px var(--shadow);
    border: none;
    font-size: 13rem;
    padding: 0 10px;
    margin-top: 5px;
    max-width: 400px;
  }
`;

const EditSportModal = ({
  openModal,
  setOpenModal,
  refreshData,
  leagueData,
}) => {
  const [errorMessage, setErrorMessage] = useState();
  const userData = localStorage.getItem('user');
  const token = JSON.parse(userData);
  const [selectedFile, setSelectedFile] = useState('');
  const [sportsList, setSportsList] = useState([]);
  const [newLeague, setNewLeague] = useState({
    name: leagueData.name,
    image: leagueData.image,
    sport: leagueData.sport.id,
  });
  const [message, setMessage] = useState('');
  const ModalLayoutRef = useRef();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewLeague({
      ...newLeague,
      [name]: value,
    });
  };

  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const HandleEditLeague = async (e) => {
    e.preventDefault();

    const dataForm = new FormData();
    if (leagueData.name !== newLeague.name) {
      dataForm.append('name', newLeague.name);
    }
    if (leagueData.sport !== newLeague.sport) {
      dataForm.append('sport', newLeague.sport);
    }
    if (selectedFile) {
      dataForm.append('image', selectedFile);
    }

    try {
      const { data } = await axiosInstance.patch(
        `${process.env.REACT_APP_BASE_URL}leagues/${leagueData.id}/`,
        dataForm
      );
      toast.success('Liga editada');
      setOpenModal(false);
      refreshData();
    } catch (error) {
      setErrorMessage(error.response.data.detail.name);
    }
  };

  const getSportsList = async () => {
    try {
      const { data } = await axiosInstance.get(
        `${process.env.REACT_APP_BASE_URL}sports/?status=1/`
      );
      setSportsList(data);
    } catch (error) {
      console.log(error.response.status);
    }
  };

  useEffect(() => {
    getSportsList();
  }, []);

  return (
    <Modal
      isOpen={openModal}
      element={
        <ModalLayout
          ref={ModalLayoutRef}
          myOwnContainer
          setOpenModal={setOpenModal}
        >
          <Wrapper>
            <div className="title">
              <h2>Agregar liga</h2>
            </div>
            <div className="body">
              <form>
                {/* <Dropdown>
                  <label htmlFor="sport">
                    Deporte al que pertenece la liga
                  </label>
                  <select
                    name="sport"
                    value={newLeague.sport}
                    required
                    onChange={handleChange}
                  >
                    {sportsList.map((sport) => {
                      return <option value={sport.id}>{sport.name}</option>;
                    })}
                  </select>
                </Dropdown> */}
                <FormField
                  type="text"
                  name="name"
                  value={newLeague.name}
                  placeholder="Escribe el nombre de la liga"
                  labelText="Nombre de la liga"
                  onChange={handleChange}
                />
                <FormField
                  type="file"
                  name="logo"
                  labelText="Icono para la liga"
                  onChange={handleFileSelect}
                />
                <div className="exampleBox">
                  <div className="iconExample">
                    <img
                      src={
                        selectedFile === ''
                          ? leagueData.image
                          : URL.createObjectURL(selectedFile)
                      }
                      alt=""
                    />
                  </div>
                  <p>{newLeague.name}</p>
                </div>
                <div
                  className="message"
                  style={{
                    color:
                      message === 'Email de recuperación enviado'
                        ? 'green'
                        : 'red',
                  }}
                >
                  {message}
                </div>
                <Button
                  text="Editar liga"
                  color="#fff"
                  backgroundColor="var(--primary)"
                  onClick={HandleEditLeague}
                />
              </form>
            </div>
          </Wrapper>
        </ModalLayout>
      }
    />
  );
};

export default EditSportModal;
