import Modal from '../modal';
import axiosInstance from '../../../utils/axios';
import styled from 'styled-components';
import ModalLayout from '../modal/ModalLayout';
import FormField from '../../atoms/FormField';
import Button from '../../atoms/button';
import { useState, useRef, useEffect } from 'react';
import { toast } from 'react-toastify';

const Wrapper = styled.div`
  text-align: center;
  width: 90%;
  max-width: 400px;
  background-color: var(--primaryBackground);
  border-radius: 5px;
  overflow: hidden;

  .title {
    background-color: #fff;
    border-bottom: solid var(--accent) 5px;
    text-align: left;
    padding: 20px;

    p {
      color: var(--secondaryText);
    }
  }

  .body {
    padding: 20px 40px 40px 40px;
  }

  input {
    max-width: unset;
  }

  input[type='file'] {
    background-color: transparent;
    box-shadow: unset;
  }
  .exampleBox {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    color: var(--primaryText);
    font-weight: 700;

    .iconExample {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      overflow: hidden;

      img {
        width: 100%;
      }
    }
  }
  .message {
    margin-bottom: 15px;
  }
`;

const ResultsItems = styled.div`
  text-align: center;
  margin-top: 20px;

  p {
    font-size: 14rem;
    margin-top: 20px;
    font-weight: 700;
  }

  & > p {
    color: var(--secondaryText);
    font-size: 16px;
    margin-bottom: 20px;
  }
`;

const Icon = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto;

  img {
    width: 100%;
  }
`;

const TeamsWrapper = styled.div`
  display: flex;
  gap: 5px;
  align-items: baseline;
  justify-content: center;
  margin-top: 5px;

  .team:nth-child(2) {
    background-color: red;
  }
}
`;

const EventResults = ({ openModal, setOpenModal, refreshData, eventData }) => {
  const [errorMessage, setErrorMessage] = useState();
  const [results, setResults] = useState({
    team1: '',
    team2: '',
  });

  const [message, setMessage] = useState('');
  const ModalLayoutRef = useRef();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setResults({
      ...results,
      [name]: value,
    });
  };

  const HandleEditTeam = async (e) => {
    e.preventDefault();

    try {
      const { data } = await axiosInstance.get(
        `${process.env.REACT_APP_BASE_URL}events/${eventData.id}/results/?${
          results.team1 === results.team2
            ? 'draw=' + results.team1
            : 'score_team1=' + results.team1 + '&score_team2=' + results.team2
        }`
      );
      toast.success('Resultado agregado');
      setOpenModal(false);
      refreshData();
    } catch (error) {
      toast.error('No se pudo cargar el resultado');
      setErrorMessage(error.response.data.detail.name);
    }
  };

  return (
    <Modal
      isOpen={openModal}
      element={
        <ModalLayout
          ref={ModalLayoutRef}
          myOwnContainer
          setOpenModal={setOpenModal}
        >
          <Wrapper>
            <div className="title">
              <h2>Agregar resultados</h2>
              <p>Agrega los resultados del evento</p>
            </div>
            <div className="body">
              <form>
                <ResultsItems>
                  <p className="league">{eventData.league.name}</p>
                  <TeamsWrapper>
                    <div className="team">
                      <Icon>
                        <img src={eventData.team1.image} alt="" />
                      </Icon>
                      <p>{eventData.team1.name}</p>
                      <FormField
                        type="text"
                        name="team1"
                        value={results.team1}
                        placeholder="Puntos del equipo 1"
                        onChange={handleChange}
                      />
                    </div>
                    <p>VS</p>
                    <div className="team">
                      <Icon>
                        <img src={eventData.team2.image} alt="" />
                      </Icon>
                      <p>{eventData.team2.name}</p>
                      <FormField
                        type="text"
                        name="team2"
                        value={results.team2}
                        placeholder="Puntos del equipo 2"
                        onChange={handleChange}
                      />
                    </div>
                  </TeamsWrapper>
                </ResultsItems>

                <div
                  className="message"
                  style={{
                    color:
                      message === 'Email de recuperación enviado'
                        ? 'green'
                        : 'red',
                  }}
                >
                  {message}
                </div>
                <Button
                  text="Enviar resultados"
                  color="#fff"
                  backgroundColor="var(--primary)"
                  onClick={HandleEditTeam}
                />
              </form>
            </div>
          </Wrapper>
        </ModalLayout>
      }
    />
  );
};

export default EventResults;
