import Modal from '../modal';
import axios from 'axios';
import styled from 'styled-components';
import ModalLayout from '../modal/ModalLayout';
import FormField from '../../atoms/FormField';
import Button from '../../atoms/button';
import { useState, useRef } from 'react';

const Wrapper = styled.div`
  width: 90%;
  max-width: 400px;
  padding: 20px;
  background-color: var(--primaryBackground);
  border-radius: 5px;

  input {
    max-width: unset;
  }

  .message {
    margin-bottom: 15px;
  }
`;

const RecoverPassModal = ({ openModal, setOpenModal }) => {
  const [recoverData, setRecoverData] = useState({
    email: '',
  });
  const [message, setMessage] = useState('');
  const ModalLayoutRef = useRef();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setRecoverData({
      ...recoverData,
      [name]: value,
    });
  };

  const HandleRecovery = async (e) => {
    e.preventDefault();
    try {
      let authresult = await axios.post(
        `${process.env.REACT_APP_BASE_URL}auth/password/recovery/`,
        recoverData
      );
      setMessage(authresult.data.message);
    } catch (err) {
      setMessage(err.response.data.message);
    }
  };

  return (
    <Modal
      isOpen={openModal}
      element={
        <ModalLayout
          ref={ModalLayoutRef}
          myOwnContainer
          setOpenModal={setOpenModal}
        >
          <Wrapper>
            <h2>¿Olvidaste la contraseña?</h2>
            <p>
              Introduce la dirección de correo electrónico que usas en
              GoChallenge. Te enviaremos un enlace para restablecer tu
              contraseña.
            </p>
            <form onSubmit={HandleRecovery}>
              <FormField
                type="text"
                name="email"
                value={recoverData.email}
                placeholder="Escribe tu direccion de correo"
                labelText="Correo"
                onChange={handleChange}
              />
              <div
                className="message"
                style={{
                  color:
                    message === 'Email de recuperación enviado'
                      ? 'green'
                      : 'red',
                }}
              >
                {message}
              </div>
              <Button
                text="Enviar correo de recuperacion"
                color="#fff"
                backgroundColor="var(--primary)"
              />
            </form>
          </Wrapper>
        </ModalLayout>
      }
    />
  );
};

export default RecoverPassModal;
