import styled from 'styled-components';
import Modal from '../../components/templates/modal';
import ModalLayout from '../../components/templates/modal/ModalLayout';
import Button from '../../components/atoms/button';
import { useState, useRef } from 'react';
import axiosInstance from '../../utils/axios';
import FormField from '../../components/atoms/FormField';
import { toast } from 'react-toastify';

const Wrapper = styled.div`
  text-align: center;
  width: 90%;
  max-width: 400px;
  background-color: var(--primaryBackground);
  border-radius: 5px;
  overflow: hidden;

  .title {
    background-color: #fff;
    text-align: left;
    padding: 20px;

    p {
      color: var(--secondaryText);
    }
  }

  form {
    margin-bottom: 20px;
  }

  .body {
    padding: 0 20px 20px 20px;
  }

  input {
    max-width: unset;
  }

  input[type='file'] {
    background-color: transparent;
    box-shadow: unset;
  }
  .exampleBox {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    color: var(--primaryText);
    font-weight: 700;

    .iconExample {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      overflow: hidden;

      img {
        width: 100%;
      }
    }
  }
  .message {
    margin-bottom: 15px;
  }
`;

const RejectIdentity = ({
  openModal,
  setOpenModal,
  user,
  refreshData,
  errorList,
}) => {
  const [blockData, setBlockData] = useState({
    reason: '',
  });
  const ModalLayoutRef = useRef();

  const blockUser = async () => {
    try {
      const { data } = await axiosInstance.post(
        `${process.env.REACT_APP_BASE_URL}client_management/${user.id}/create_log/`,
        { action_realized: 'block', reason: blockData.reason }
      );
      setOpenModal(false);
      refreshData();
    } catch (error) {
      console.log(error.response.data.detail.name);
    }
  };

  /* const handleRejectIdentity = async () => {
    try {
      const { data } = await axiosInstance.patch(
        `${process.env.REACT_APP_BASE_URL}identities/validate/${user.id}/`,
        rejectData
      );
      toast.success('Documento rechazado');
      setOpenModal(false);
    } catch (error) {
      console.log(error.response.data.detail.name);
    }
  }; */

  const handleChange = (e) => {
    const { name, value } = e.target;
    setBlockData({
      ...blockData,
      [name]: value,
    });
  };

  return (
    <Modal
      isOpen={openModal}
      element={
        <ModalLayout
          ref={ModalLayoutRef}
          myOwnContainer
          setOpenModal={setOpenModal}
        >
          <Wrapper>
            <div className="title">
              <h2>Confirmas el bloqueo de {user.username}</h2>
            </div>
            <div className="body">
              <form>
                <FormField
                  type="text"
                  name="reason"
                  labelText="Describa la razon del bloqueo"
                  onChange={handleChange}
                />
              </form>
              <Button
                text="Bloquear usuario"
                color="#fff"
                backgroundColor="var(--primary)"
                onClick={blockUser}
              />
            </div>
          </Wrapper>
        </ModalLayout>
      }
    />
  );
};

export default RejectIdentity;
