import styled from 'styled-components';
import Modal from '../../components/templates/modal';
import ModalLayout from '../../components/templates/modal/ModalLayout';
import Button from '../../components/atoms/button';
import { useState, useRef, useEffect } from 'react';
import axiosInstance from '../../utils/axios';
import FormField from '../../components/atoms/FormField';
import { toast } from 'react-toastify';
import UserClients from '.';

const Wrapper = styled.div`
  text-align: center;
  width: 90%;
  max-width: 400px;
  background-color: var(--primaryBackground);
  border-radius: 5px;
  overflow: hidden;

  .title {
    background-color: #fff;
    text-align: left;
    padding: 20px;

    p {
      color: var(--secondaryText);
    }
  }

  .body {
    padding: 20px;
  }

  input {
    max-width: unset;
  }

  input[type='file'] {
    background-color: transparent;
    box-shadow: unset;
  }
  .exampleBox {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    color: var(--primaryText);
    font-weight: 700;

    .iconExample {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      overflow: hidden;

      img {
        width: 100%;
      }
    }
  }
  .message {
    margin-bottom: 15px;
  }
`;

const Form = styled.form`
  .profilePicture {
    display: flex;
    align-items: center;

    span {
      font-size: 12rem;
    }

    input {
      width: 0.1px;
      height: 0.1px;
    }

    label {
      font-weight: 700;
    }

    input::before {
      content: '+';
      position: absolute;
      cursor: pointer;
      font-weight: 700;
      font-size: 50rem;
      color: var(--primary);
      top: 0;
      left: 0;
      width: 60px;
      height: 60px;
      text-align: center;
      line-height: 60px;
    }

    .profile_picture {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      position: relative;
      overflow: hidden;

      img {
        width: 100%;
      }
    }
  }

  div {
    flex-direction: row;
    align-items: center;

    label {
      width: 200px;
      text-align: left;
    }

    input {
      width: 100%;
      max-width: 400px;
    }
  }
`;

const Dropdown = styled.div`
  display: flex;
  padding-bottom: 20px;

  label {
    font-weight: 700;
  }

  select {
    height: 30px;
    width: 100%;
    border-radius: 10px;
    background-color: var(--secondaryBackground);
    box-shadow: 0px 4px 4px -4px var(--shadow);
    border: none;
    font-size: 13rem;
    padding: 0 10px;
    margin-top: 5px;
    max-width: 400px;
  }
`;

const EditUser = ({ openModal, setOpenModal, user, refreshData }) => {
  const ModalLayoutRef = useRef();
  const [message, setMessage] = useState('');
  const [rolesList, setRolesList] = useState([]);
  const [updatedData, setUpdatedData] = useState({});
  const [profileData, setProfileData] = useState({
    id: '',
    avatar: null,
    preferences: [],
    email: '',
    username: '',
    first_name: '',
    last_name: '',
    date_of_birth: '',
    phone: '',
    user_role: '',
    personal_description: '',
    status: '',
    is_email_verified: null,
    e_wallet: '',
  });

  const submitUserUpdate = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axiosInstance.patch(
        `${process.env.REACT_APP_BASE_URL}auth/users/${user.id}/`,
        updatedData
      );
      toast.success('Datos actualizados con exito');
    } catch (error) {
      console.log(error.response.data);
      toast.warn(`Error`);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUpdatedData({
      ...updatedData,
      [name]: value,
    });
  };

  const getUserData = async () => {
    try {
      const { data } = await axiosInstance.get(
        `${process.env.REACT_APP_BASE_URL}auth/users/${user.id}/`
      );
      setProfileData(data);
    } catch (error) {
      // toast.success('Hubo un error aceptando el reto');
      console.log(error.response.data.detail.name);
    }
  };

  const getRolsList = async () => {
    try {
      const { data } = await axiosInstance.get(
        `${process.env.REACT_APP_BASE_URL}auth/user_roles/`
      );

      setRolesList(data.results);
    } catch (error) {
      console.log(error.response.data.detail.name);
    }
  };

  useEffect(() => {
    getUserData();
    getRolsList();
  }, []);

  return (
    <Modal
      isOpen={openModal}
      element={
        <ModalLayout
          ref={ModalLayoutRef}
          myOwnContainer
          setOpenModal={setOpenModal}
        >
          <Wrapper>
            <div className="title">
              <h2>Editar datos de {user.username}</h2>
            </div>
            <div className="body">
              <Form>
                {/* <div className="profilePicture">
                  <label htmlFor="profilePicture">
                    Fotografía <span className="textSecondary">(Max 3Mb)</span>
                  </label>
                  <InputFileWrapper>
                    <div
                      className="profile_picture"
                      style={{ flex: '0 0 60px' }}
                    >
                      <img
                        src={
                          selectedFile != ''
                            ? URL.createObjectURL(selectedFile)
                            : profileData.avatar
                        }
                        alt=""
                      />
                      <input
                        type="file"
                        name="profilePicture"
                        onChange={handleFileSelect}
                      />
                    </div> */}
                {/* <button>Eliminar</button> */}
                {/* </InputFileWrapper>
                </div> */}
                <FormField
                  type="text"
                  name="first_name"
                  value={updatedData.first_name || profileData.first_name}
                  placeholder="Escribe tu nombre"
                  labelText="Nombre"
                  onChange={handleChange}
                />
                <FormField
                  type="text"
                  name="last_name"
                  value={updatedData.last_name || profileData.last_name}
                  placeholder="Escribe tu apellido"
                  labelText="Apellido"
                  onChange={handleChange}
                />
                <FormField
                  type="text"
                  name="username"
                  value={updatedData.username || profileData.username}
                  placeholder="Escribe el nombre como quieres que te vean los demás usuarios"
                  labelText={<p style={{ lineHeight: '14rem' }}>Username</p>}
                  onChange={handleChange}
                />
                <FormField
                  type="date"
                  name="date_of_birth"
                  value={updatedData.date_of_birth || profileData.date_of_birth}
                  labelText="Fecha de nacimiento"
                  onChange={handleChange}
                />
                <FormField
                  type="text"
                  name="personal_description"
                  value={
                    updatedData.personal_description ||
                    profileData.personal_description
                  }
                  placeholder="Escribe una breve descripcion personal"
                  labelText="Descripcion personal"
                  onChange={handleChange}
                  disabled
                />
                <FormField
                  type="number"
                  name="phone"
                  value={updatedData.phone || profileData.phone}
                  placeholder="Escribe tu numero telefónico"
                  labelText="Teléfono (Max. 10 digitos)"
                  onChange={handleChange}
                />
                <Dropdown>
                  <label htmlFor="league">Rol</label>
                  <select
                    name="league"
                    required
                    value={updatedData.user_role || profileData.user_role}
                    onChange={handleChange}
                  >
                    <option value="">Selecciona el rol</option>
                    {rolesList.map((rol) => {
                      return (
                        <option value={rol.id} key={rol.id}>
                          {rol.name}
                        </option>
                      );
                    })}
                  </select>
                </Dropdown>
                <div>
                  <Button
                    text="Guardar cambios"
                    color="#fff"
                    backgroundColor="#324250"
                    onClick={submitUserUpdate}
                  />
                </div>
              </Form>
            </div>
          </Wrapper>
        </ModalLayout>
      }
    />
  );
};

export default EditUser;
