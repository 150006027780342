import { useState, useEffect } from 'react';
import Main from '../../layouts/main';
import styled from 'styled-components';
import Button from '../../components/atoms/button';
import { toast } from 'react-toastify';
import axiosInstance from '../../utils/axios';
import Papa from 'papaparse';

const Wrapper = styled.div`
  background-color: var(--primaryBackground);
  display: flex;
  height: 100%;
  .main {
    padding: 20px;
    width: 100%;
    height: calc(100vh - 140px);
    overflow-y: scroll;

    .addButtonsBox {
      padding: 20px;
      display: flex;
      justify-content: space-around;
    }
  }

  .info {
    background-color: var(--secondaryBackground);
    width: 500px;
    height: calc(100vh - 140px);
    overflow-y: auto;
  }

  table {
    border-collapse: collapse;
    width: 100%;
  }

  th,
  td {
    text-align: center;
    padding: 8px;
    width: 20%;
  }

  tr:nth-child(even) {
    background-color: #d6eeee;
  }

  tr:hover {
    filter: opacity(0.5);
  }
`;

const LiveEventsPage = () => {
  const [sportsList, setSportsList] = useState([]);
  const [leaguesList, setLeaguesList] = useState([]);
  const [teamsList, setTeamsList] = useState([]);
  const [selectedFile, setSelectedFile] = useState([]);
  const [fileToSend, setFileToSend] = useState('');

  const HandleFileSelect = (event) => {
    Papa.parse(event.target.files[0], {
      header: true,
      complete: function (results) {
        setSelectedFile(results.data);
      },
    });
    setFileToSend(event.target.files[0]);
  };

  const DownloadCSV = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axiosInstance.get(
        `${process.env.REACT_APP_BASE_URL}events/csv/`
      );

      const blob = new Blob([data], { type: 'text/csv' });

      // Creating an object for downloading url
      const url = window.URL.createObjectURL(blob);

      // Creating an anchor(a) tag of HTML
      const a = document.createElement('a');

      // Passing the blob downloading url
      a.setAttribute('href', url);

      // Setting the anchor tag attribute for downloading
      // and passing the download file name
      a.setAttribute('download', 'ModeloParaSubidaDeEventos.csv');

      // Performing a download with click
      a.click();
    } catch (error) {
      // setMessage(error.response.data.detail.error[0]);
    }
  };

  console.log(selectedFile);

  const SendCSV = async (e) => {
    const dataForm = new FormData();
    dataForm.append('events_csv', fileToSend);

    e.preventDefault();
    try {
      const { data } = await axiosInstance.post(
        `${process.env.REACT_APP_BASE_URL}events/csv/`,
        dataForm
      );
    } catch (error) {
      // setMessage(error.response.data.detail.error[0]);
    }
  };

  const getSportsList = async () => {
    try {
      const { data } = await axiosInstance.get(
        `${process.env.REACT_APP_BASE_URL}sports/?status=1/`
      );
      setSportsList(data);
    } catch (error) {
      // toast.success('Hubo un error aceptando el reto');
      console.log(error.response.data.detail.name);
    }
  };

  const getLeaguesList = async () => {
    try {
      const { data } = await axiosInstance.get(
        `${process.env.REACT_APP_BASE_URL}leagues/`
      );
      setLeaguesList(data);
    } catch (error) {
      // toast.success('Hubo un error aceptando el reto');
      console.log(error.response.data.detail.name);
    }
  };

  const getTeamsList = async () => {
    try {
      const { data } = await axiosInstance.get(
        `${process.env.REACT_APP_BASE_URL}teams/?status=1/`
      );
      setTeamsList(data);
    } catch (error) {
      console.log(error.response.data.detail.name);
    }
  };

  useEffect(() => {
    getSportsList();
    getTeamsList();
    getLeaguesList();
  }, []);

  return (
    <Main>
      <Wrapper>
        <div className="main" id="scrollableDiv">
          <div style={{ padding: '20px' }}>
            <h2>Carga por lotes de eventos</h2>
            <p>
              Ten en cuenta que todos los nombres deben coincidir con los
              creados en la plataforma, es sensible a mayusculas.
            </p>
          </div>
          <div className="addButtonsBox">
            <Button
              text="Descargar modelo de CSV"
              color="#fff"
              backgroundColor="#324250"
              onClick={DownloadCSV}
            />
            <input
              type="file"
              name="profilePicture"
              onChange={HandleFileSelect}
            />
            <Button
              text="Cargar CSV"
              color="#fff"
              backgroundColor="#324250"
              onClick={SendCSV}
            />
          </div>
          <div className="events">
            <table>
              <tr>
                <th>Fecha de inicio</th>
                <th>Deporte</th>
                <th>Liga</th>
                <th>Equipo 1</th>
                <th>Equipo 2</th>
              </tr>
              {selectedFile.map((sportEvent) => {
                return (
                  <tr>
                    <td>{sportEvent.start_datetime}</td>
                    <td
                      style={{
                        color: sportsList.some(
                          (item) => item.name === sportEvent.sport
                        )
                          ? 'green'
                          : 'red',
                      }}
                    >
                      {sportEvent.sport}
                    </td>
                    <td
                      style={{
                        color: leaguesList.some(
                          (item) => item.name === sportEvent.league
                        )
                          ? 'green'
                          : 'red',
                      }}
                    >
                      {sportEvent.league}
                    </td>
                    <td
                      style={{
                        color: teamsList.some(
                          (item) => item.name === sportEvent.team1
                        )
                          ? 'green'
                          : 'red',
                      }}
                    >
                      {sportEvent.team1}
                    </td>
                    <td
                      style={{
                        color: teamsList.some(
                          (item) => item.name === sportEvent.team2
                        )
                          ? 'green'
                          : 'red',
                      }}
                    >
                      {sportEvent.team2}
                    </td>
                  </tr>
                );
              })}
            </table>
          </div>
        </div>
      </Wrapper>
      {/* {openSportModal && (
        <AddSportModal
          openModal={openSportModal}
          setOpenModal={setOpenSportModal}
        />
      )}
      {openLeagueModal && (
        <AddLeagueModal
          openModal={openLeagueModal}
          setOpenModal={setOpenLeagueModal}
        />
      )}
      {openTeamModal && (
        <AddTeamModal
          openModal={openTeamModal}
          setOpenModal={setOpenTeamModal}
        />
      )}
      {openEventModal && (
        <AddEventModal
          openModal={openEventModal}
          setOpenModal={setOpenEventModal}
        />
      )} */}
    </Main>
  );
};

export default LiveEventsPage;
