import React from 'react';
import styled from 'styled-components';
import Menu from './menu';

const Wrapper = styled.div`
  display: flex;
  height: 100%;

  .main {
    padding: 40px;
    width: 100%;
  }
`;

const Account = (props) => {
  return (
    <Wrapper>
      <Menu />
      <div className="main">{props.children}</div>
    </Wrapper>
  );
};

export default Account;
